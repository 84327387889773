import React from 'react'
import {ReactComponent as CameraIcon} from '../../assets/camera.svg'

export const FileInput = (props: React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
      <button
          {...props}
          className="w-24 h-24 bg-neutral-100 rounded-lg flex justify-center items-center"
      >
        <div
            className="w-14 h-14 bg-white rounded-full border border-gray-200 flex justify-center items-center">
          <CameraIcon className="w-6 h-6"/>
        </div>
      </button>
  )
}
