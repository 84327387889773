import { Button } from "../../../components/Button";
import { ReactComponent as BackIcon } from "../../../assets/back.svg";
import { ReactComponent as NextIcon } from "../../../assets/next.svg";

export function ActionButtons({
  onBack,
  onNext,
  disableNext,
  loading,
}: {
  onBack?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onNext?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disableNext?: boolean;
  loading?: boolean;
}) {
  const wrapperClass = onBack ? 'justify-between': 'justify-end'
  return (
    <div className={`flex ${wrapperClass} gap-3 items-center flex-wrap pr-1`}>
      {onBack && (
        <Button variant="outline" onClick={onBack} className={'order-first ml-1'}>
          <BackIcon
            className="[&_path]:stroke-primary"
            width={20}
            height={20}
          />
          <span>Back</span>
        </Button>
      )}
      {onNext && (
        <Button
          loading={loading}
          variant="primary"
          onClick={onNext}
          disabled={disableNext || loading}
          className={'order-last'}
        >
          <span>Next</span>
          {!loading && (
            <NextIcon
              className="[&_path]:stroke-white"
              width={20}
              height={20}
            />
          )}
        </Button>
      )}
    </div>
  );
}
