/**
 * Generated by orval v6.15.0 🍺
 * Do not edit manually.
 * Guidely
 * The backend APIs
 * OpenAPI spec version: 1.0
 */
import useSwr from "swr";
import type { SWRConfiguration, Key } from "swr";
import type {
  GetCtasDto,
  CreateCtaDto,
  UpdateCtaDto,
  CtaFindAllUserCtaParams,
  UpdateMultipleCtaDto,
} from ".././schemas";
import { customInstance } from "../../customInstance";

export const ctaCreate = (createCtaDto: CreateCtaDto) => {
  return customInstance<GetCtasDto>({
    url: `/cta`,
    method: "post",
    headers: { "Content-Type": "application/json" },
    data: createCtaDto,
  });
};

export const ctaUpdate = (updateCtaDto: UpdateCtaDto) => {
  return customInstance<GetCtasDto>({
    url: `/cta`,
    method: "patch",
    headers: { "Content-Type": "application/json" },
    data: updateCtaDto,
  });
};

export const ctaFindAllUserCta = (params?: CtaFindAllUserCtaParams) => {
  return customInstance<GetCtasDto[]>({ url: `/cta`, method: "get", params });
};

export const getCtaFindAllUserCtaKey = (params?: CtaFindAllUserCtaParams) =>
  [`/cta`, ...(params ? [params] : [])] as const;

export type CtaFindAllUserCtaQueryResult = NonNullable<
  Awaited<ReturnType<typeof ctaFindAllUserCta>>
>;
export type CtaFindAllUserCtaQueryError = unknown;

export const useCtaFindAllUserCta = <TError = unknown>(
  params?: CtaFindAllUserCtaParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof ctaFindAllUserCta>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getCtaFindAllUserCtaKey(params) : null));
  const swrFn = () => ctaFindAllUserCta(params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

export const ctaCreateMultiple = (createCtaDto: CreateCtaDto[]) => {
  return customInstance<GetCtasDto[]>({
    url: `/cta/multiple`,
    method: "post",
    headers: { "Content-Type": "application/json" },
    data: createCtaDto,
  });
};

export const ctaUpdateMultiple = (
  updateMultipleCtaDto: UpdateMultipleCtaDto,
) => {
  return customInstance<void>({
    url: `/cta/multiple`,
    method: "patch",
    headers: { "Content-Type": "application/json" },
    data: updateMultipleCtaDto,
  });
};

export const ctaFindOne = (id: string) => {
  return customInstance<GetCtasDto>({ url: `/cta/${id}`, method: "get" });
};

export const getCtaFindOneKey = (id: string) => [`/cta/${id}`] as const;

export type CtaFindOneQueryResult = NonNullable<
  Awaited<ReturnType<typeof ctaFindOne>>
>;
export type CtaFindOneQueryError = unknown;

export const useCtaFindOne = <TError = unknown>(
  id: string,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof ctaFindOne>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!id;
  const swrKey =
    swrOptions?.swrKey ?? (() => (isEnabled ? getCtaFindOneKey(id) : null));
  const swrFn = () => ctaFindOne(id);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

export const ctaRemove = (id: string) => {
  return customInstance<void>({ url: `/cta/${id}`, method: "delete" });
};
