import React from 'react'
import {ActionButtons} from './ActionButtons'
import {OnboardingStepsEnum, OnboardingStepsNamesEnum} from '../../../types'
import {clearTokenAndUser} from '../../../services/utils'
import {useNavigate} from 'react-router-dom'
import {MainUserProfile} from '../../../components/MainUserProfile'
import {
  MainUserProfileContext,
  MainUserProfileProvider,
} from '../../../components/MainUserProfile/MainUserProfile.context'

export function WelcomeCouch({
                               onNext,
                               stepCode,
                             }: {
  onBack: () => void;
  onNext: (data: any) => void;
  metaData: any;
  data;
  stepCode: OnboardingStepsEnum
}) {
  const navigate = useNavigate()

  const handleClose = async () => {
    clearTokenAndUser()
    navigate('/login')
  }

  return <div className="w-full overflow-auto max-h-[calc(100vh-125px)] py-2">
        <MainUserProfileProvider stepCode={OnboardingStepsNamesEnum.WELCOME_COACH} onSaved={onNext}>
          <MainUserProfileContext.Consumer>
            {({handleNext, loading, form}) => {
              const disabled = !(form.displayName && form.instagramHandle &&
                  form?.keywords?.length)
              return <>
                <MainUserProfile isExitButtonEnabled={true}
                                 handleExitButton={handleClose}/>
                <div className="my-8 border-t border-lightGray"></div>
                <ActionButtons
                    onNext={handleNext}
                    disableNext={disabled}
                    loading={loading}
                />
              </>
            }}
          </MainUserProfileContext.Consumer>
        </MainUserProfileProvider>
      </div>
}
