import React, { useEffect } from "react";
import { api } from "../../../services/api";
import { getUser } from "../../../services/utils";
import { ShowToast } from "../../../services/toast";

export const UserContext = React.createContext({});

export function UserProvider({ children }) {
  const [userInfo, setUserInfo] = React.useState({});
  const [userInfoLoading, setUserInfoLoading] = React.useState(false);
  const user = getUser();
  const fetchUser = async (userId: string) => {
    try {
      setUserInfoLoading(true);
      const response = await api.getUserProfile(userId);
      setUserInfo({
        ...response.onboardingSubmissions,
        ...response,
      });
      setUserInfoLoading(false);
    } catch (error) {
      ShowToast({
        message: "Something went wrong while fetching user info",
        type: "error",
      });
    }
  };

  useEffect(() => {
    if (user?._id) {
      fetchUser(user?._id);
    }
  }, [user?._id]);

  const values = {
    userInfo,
    setUserInfo,
    fetchUser,
    loading: userInfoLoading,
  };

  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
}

export function useUser() {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
}
