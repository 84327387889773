import React from 'react'
import Tippy from '@tippyjs/react/headless'

export type TextInputParams = {
  hasError?: boolean
  errorElement?: React.ReactNode
  errorMessage?: string
  onClose?: (params, event) => void
}

export function TextInput({
                            hasError,
                            errorElement,
                            errorMessage,
                            onClose,
                            ...props
                          }: React.InputHTMLAttributes<HTMLInputElement> & TextInputParams) {
  const borderClasses = hasError ?
      'border-red focus:border-red2' :
      'border-lightGray focus:border-grey2'
  const inputStyles = hasError ? {borderColor: 'red'} : {}

  return (
      <div className="w-full">
        <Tippy
            disabled={!hasError}
            visible={hasError}
            placement={'top-end'}
            arrow={true}
            sticky={'popper'}
            onClickOutside={onClose}
            render={attrs => (
                <div>
                  <div
                      className="box bg-white p-3 border border-lightGray shadow-md rounded-lg"
                      {...attrs}
                  >
                    {hasError ? errorElement ? errorElement : errorMessage : ''}
                  </div>
                </div>
            )}>
          <input
              {...props}
              className={`!outline-none w-full bg-ultraLightGray focus:bg-white rounded-lg border ${borderClasses} p-5 text-base text-black ${
                  props.className || ''
              }`}
              style={inputStyles}
          />
        </Tippy>
      </div>
  )
}
