/**
 * Generated by orval v6.15.0 🍺
 * Do not edit manually.
 * Guidely
 * The backend APIs
 * OpenAPI spec version: 1.0
 */
import useSwr from "swr";
import type { SWRConfiguration, Key } from "swr";
import type {
  GetTopicIdeasDto,
  CreateTopicIdeaDto,
  UpdateTopicIdeaDto,
  TopicIdeaFindAllUserTopicIdeaParams,
  UpdateMultipleTopicIdeaDto,
} from ".././schemas";
import { customInstance } from "../../customInstance";

export const topicIdeaCreate = (createTopicIdeaDto: CreateTopicIdeaDto) => {
  return customInstance<GetTopicIdeasDto>({
    url: `/topic-ideas`,
    method: "post",
    headers: { "Content-Type": "application/json" },
    data: createTopicIdeaDto,
  });
};

export const topicIdeaUpdate = (updateTopicIdeaDto: UpdateTopicIdeaDto) => {
  return customInstance<GetTopicIdeasDto>({
    url: `/topic-ideas`,
    method: "patch",
    headers: { "Content-Type": "application/json" },
    data: updateTopicIdeaDto,
  });
};

export const topicIdeaFindAllUserTopicIdea = (
  params?: TopicIdeaFindAllUserTopicIdeaParams,
) => {
  return customInstance<GetTopicIdeasDto[]>({
    url: `/topic-ideas`,
    method: "get",
    params,
  });
};

export const getTopicIdeaFindAllUserTopicIdeaKey = (
  params?: TopicIdeaFindAllUserTopicIdeaParams,
) => [`/topic-ideas`, ...(params ? [params] : [])] as const;

export type TopicIdeaFindAllUserTopicIdeaQueryResult = NonNullable<
  Awaited<ReturnType<typeof topicIdeaFindAllUserTopicIdea>>
>;
export type TopicIdeaFindAllUserTopicIdeaQueryError = unknown;

export const useTopicIdeaFindAllUserTopicIdea = <TError = unknown>(
  params?: TopicIdeaFindAllUserTopicIdeaParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof topicIdeaFindAllUserTopicIdea>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getTopicIdeaFindAllUserTopicIdeaKey(params) : null));
  const swrFn = () => topicIdeaFindAllUserTopicIdea(params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

export const topicIdeaCreateMultiple = (
  createTopicIdeaDto: CreateTopicIdeaDto[],
) => {
  return customInstance<GetTopicIdeasDto[]>({
    url: `/topic-ideas/multiple`,
    method: "post",
    headers: { "Content-Type": "application/json" },
    data: createTopicIdeaDto,
  });
};

export const topicIdeaUpdateMultiple = (
  updateMultipleTopicIdeaDto: UpdateMultipleTopicIdeaDto,
) => {
  return customInstance<void>({
    url: `/topic-ideas/multiple`,
    method: "patch",
    headers: { "Content-Type": "application/json" },
    data: updateMultipleTopicIdeaDto,
  });
};

export const topicIdeaFindOne = (id: string) => {
  return customInstance<GetTopicIdeasDto>({
    url: `/topic-ideas/${id}`,
    method: "get",
  });
};

export const getTopicIdeaFindOneKey = (id: string) =>
  [`/topic-ideas/${id}`] as const;

export type TopicIdeaFindOneQueryResult = NonNullable<
  Awaited<ReturnType<typeof topicIdeaFindOne>>
>;
export type TopicIdeaFindOneQueryError = unknown;

export const useTopicIdeaFindOne = <TError = unknown>(
  id: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof topicIdeaFindOne>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!id;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getTopicIdeaFindOneKey(id) : null));
  const swrFn = () => topicIdeaFindOne(id);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

export const topicIdeaRemove = (id: string) => {
  return customInstance<void>({ url: `/topic-ideas/${id}`, method: "delete" });
};
