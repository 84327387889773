import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Sidebar } from "../components/Sidebar";
import { useEffect, useState } from "react";
import { Header } from "../components/Header";
import { api } from "../services/api";
import { MobileSidebar } from "../components/Sidebar/MobileSidebar";
import { UserProvider, useUser } from "./profile/context/user-context";
import { identifyMixPanel } from "../services/mixpanel";
import { getUser } from "../services/utils";
import { isJSON } from "../services/utils";

function MainApp() {
  const user = getUser();
  const { userInfo } = useUser() as any;
  const [collapsed, setCollapsed] = useState(false);
  const [instagramConnected, setInstagramConnected] = useState<
    boolean | undefined
  >(undefined);

  const fetchInstagramConnected = (userId) => {
    if (!userId) return;
    api
      .getInstagramProfile(userId)
      .then((res) => {
        if (res.status === 200 && res?.data?.username) {
          setInstagramConnected(true);
        } else {
          setInstagramConnected(false);
        }
      })
      .catch((err) => {
        setInstagramConnected(false);
      });
  };

  useEffect(() => {
    fetchInstagramConnected(user._id);
  }, [user._id]);

  return (
    <div className="flex-1 flex">
      {!collapsed && <Sidebar user={userInfo} />}
      {collapsed && (
        <MobileSidebar
          user={userInfo}
          onClose={() => setCollapsed((prev) => !prev)}
        />
      )}
      <div className="flex-1 max-h-screen overflow-auto">
        <Header user={userInfo} onMenuOpen={() => setCollapsed(true)} />
        <Outlet
          context={{
            instagramConnected,
            fetchInstagramConnected,
          }}
        />
      </div>
    </div>
  );
}

export function Main() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // TODO: remove token & user from localStorage when logging out
  useEffect(() => {
    const token = localStorage.getItem("token");
    const localUserData = localStorage.getItem("user") || "{}";
    let userData;
    if (isJSON(localUserData)) {
      userData = JSON.parse(localUserData);
    }
    if (!token || !userData?._id || !userData?.isOnboardingCompleted) {
      navigate("/login", {
        state: { from: `${location.pathname}${location.search}` },
      });
      setIsLoggedIn(false);
    } else {
      setIsLoggedIn(true);
      if (userData?.isOnboardingCompleted) {
        if (location.pathname === "/") {
          navigate("/dashboard");
        }
      } else {
        navigate("/onboarding");
      }
      // fetch subscription data
      // api
      //   .getSubscription(userData?._id)
      //   .then((subscriptionResponse) => {
      //     if (
      //       subscriptionResponse?.data?.length > 0 &&
      //       subscriptionResponse.data[0]?.status === "Paid"
      //     ) {
      //       // identifyMixPanel(userData._id);
      //       setIsLoggedIn(true);
      //       if (location.pathname === "/") {
      //         navigate("/dashboard");
      //       }
      //     } else {
      //       navigate("/subscription", {
      //         state: { from: `${location.pathname}${location.search}` },
      //       });
      //       setIsLoggedIn(false);
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //     setIsLoggedIn(false);
      //     navigate("/subscription", {
      //       state: { from: `${location.pathname}${location.search}` },
      //     });
      //   });
    }
  }, [location]);

  if (!isLoggedIn) return null;

  return (
    <UserProvider>
      <MainApp />
    </UserProvider>
  );
}
