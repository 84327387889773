import { Link, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Lock } from "../../../assets/lock.svg";
import { Button } from "../../../components/Button";

export function PaymentView({
  isFromPostModal = false,
}: {
  isFromPostModal?: boolean;
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const handleSubscribe = () => {
    navigate("/subscription", {
      state: { from: `${location.pathname}${location.search}` },
    });
  };
  return (
    <div
      className={`payment-view flex flex-col items-center justify-center ${
        isFromPostModal ? "pb-8" : "pb-0 mb-4"
      } border-b border-[#D2D2D2]`}
    >
      <div className="p-[15px] rounded-full w-fit border border-primary flex">
        <Lock className="w-8 h-8" />
      </div>
      <div className="mb-1 text-dark1 text-lg md:text-2xl font-semibold leading-loose">
        Start Your 30-Day Free Trial
      </div>
      <p className="text-center text-dark2 text-sm font-normal leading-normal ">
        {isFromPostModal ? (
          <>
            Create and schedule dozens of posts in minutes - <br /> all created
            using your unique tone and brand.
          </>
        ) : (
          <>
            We create and schedule a month's worth of content for you, <br /> so
            you can focus on your clients instead of Instagram.
          </>
        )}
      </p>
      <div className="mt-3 text-center text-neutral-800 text-sm font-medium font-['Inter']">
        {isFromPostModal ? (
          <>
            No commitments. <br />
            Cancel online any time.
          </>
        ) : (
          <>
            Cancel anytime - <br />
            no commitment required
          </>
        )}
      </div>

      <div className="my-3 flex items-center">
        <Link to="/subscription">
          <Button
            variant="primary"
            className="w-full md:w-auto"
            onClick={handleSubscribe}
          >
            Start Trial
          </Button>
        </Link>
      </div>
      <p className="text-[#484848] text-sm">
        {isFromPostModal
          ? "Below is an example of a post we've created and an FAQ video"
          : ""}
      </p>
    </div>
  );
}
