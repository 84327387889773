import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'
import {AxiosError} from 'axios'

import {
  IOnboardingStepMap,
  OnboardingStepsNamesEnum,
} from '../../types'
import {api} from '../../services/api'

export type MainUserProfileProviderParams = {
  children: ReactNode
  stepCode: OnboardingStepsNamesEnum
  onSaved: (params: any) => void
}

export type MainUserProfileFormType = {
  displayName: string
  instagramHandle: string
  keywords: string[]
  watermark?: string
}

export type MainUserProfileContextType = {
  currentStepData: IOnboardingStepMap | null
  loading: boolean
  form: MainUserProfileFormType
  errors: Record<string, any>
  setForm: (formData: any) => void
  onCloseError: () => void
  handleNext: (e: React.MouseEvent<HTMLButtonElement>) => Promise<void>
  onSelectKeywords: (keywords: string[]) => void
}

export const MainUserProfileContextDefaults = {
  form: {displayName: '', instagramHandle: '', keywords: [], watermark: ''},
  currentStepData: null,
  loading: false,
  errors: {},
  handleNext: () => Promise.resolve(),
  onSelectKeywords(keywords: string[]): void {
  },
  onCloseError(): void {
  },
  setForm(formData: MainUserProfileFormType): void {
  },
}

export const MainUserProfileContext = createContext<MainUserProfileContextType>(MainUserProfileContextDefaults)

export function MainUserProfileProvider({
                                          children,
                                          onSaved,
                                          stepCode,
                                        }: MainUserProfileProviderParams) {
  const [loading, setLoading] = useState<boolean>(false)
  const [currentStepData, setCurrentStepData] = useState<IOnboardingStepMap | null>(
      null)
  const [form, setForm] = useState<MainUserProfileFormType>(
      MainUserProfileContextDefaults.form)
  const [errors, setErrors] = useState<Record<string, string[]>>({})

  const handleNext = async (e: React.MouseEvent<HTMLButtonElement>) => {
    if (form.displayName && form.instagramHandle && form?.keywords?.length) {
      setLoading(true)
      try {
        const response = await api.saveOnboardingSection(
            OnboardingStepsNamesEnum[stepCode], {
              displayName: form.displayName,
              instagramHandle: form.instagramHandle,
              keywords: form.keywords,
              watermark: form?.watermark,
            })
        setLoading(false)
        setErrors({})
        if (response.status === 200) {
          onSaved({})
        }
      } catch (e: unknown) {
        console.error(e)
        const error = e as AxiosError<{ message: string, errors: Record<string, string[]> }>
        console.error(error.response?.data)
        if (error.response?.data?.errors) {
          setErrors(error.response.data.errors)
        }
        setLoading(false)
      }

    }
  }

  const onCloseError = () => {
    setErrors({})
  }


  useEffect(() => {
    if (!currentStepData && !loading) {
      setLoading(true)
      api.getOnboardingSection(OnboardingStepsNamesEnum[stepCode])
          .then(result => {
            const questions: Record<string, any> = {}
            result.questions.forEach((item) => {
              questions[item.fieldName] = item
            })
            const currentStepsData = {...result, questions}
            setCurrentStepData(currentStepsData)
            const formData = {
              displayName: questions['displayName']?.answer,
              instagramHandle: questions['instagramHandle']?.answer,
              keywords: questions['keywords']?.answer,
              watermark: questions['watermark']?.answer,
            }
            setForm(formData)
            setLoading(false)
          }).catch(errors => {
        setLoading(false)
      })
    }
  }, [currentStepData, setCurrentStepData, stepCode, loading])

  const onSelectKeywords = (keywords: string[]) => {
    setForm((prev) => {
      return {...prev, keywords}
    })
  }

  const values = {
    loading,
    errors,
    form,
    setForm,
    currentStepData,
    handleNext,
    onSelectKeywords,
    onCloseError,
  }

  return <MainUserProfileContext.Provider value={values} children={children}/>
}

export function useMainUserProfileContext() {
  const context = useContext(MainUserProfileContext)
  if (context === undefined) {
    throw new Error(
        'useMainUserProfileContext must be used within a MainUserProfileProvider')
  }
  return context
}
