import React from 'react'

import {IBrandStyleTypography} from '../../../types'

const fontWeightsTitle = [
  {
    name: 'Thin',
    weight: 100,
  },
  {
    name: 'Extra-light',
    weight: 200,
  },
  {
    name: 'Light',
    weight: 300,
  },
  {
    name: 'Regular',
    weight: 400,
  },
  {
    name: 'Medium',
    weight: 500,
  },
  {
    name: 'Semi-bold',
    weight: 600,
  },
  {
    name: 'Bold',
    weight: 700,
  },
  {
    name: 'Extra-bold',
    weight: 800,
  },
  {
    name: 'Black',
    weight: 900,
  },
]

export type FontsStyleParams = {
  fonts?: IBrandStyleTypography
}
export const FontsStyle = ({fonts}: FontsStyleParams) => {
  if (!fonts) {
    return null
  }
  return <div
      className="flex flex-col md:flex-row justify-between flex-wrap gap-8 mt-4">
    <div className="flex-1">
      <div className="flex items-center gap-4 mb-4">
        <h3 className="text-neutral-800 text-sm font-normal leading-normal">
          Use for H1, headline, titles, quote
        </h3>
      </div>
      <div
          className="grid grid-cols-3 md:grid-cols-2 lg:grid-cols-3 gap-5 flex-wrap">
        {fontWeightsTitle.map((weight) => (
            <div
                key={weight.name}
                className="text-xl font-inter font-semibold"
                style={{
                  fontWeight: weight.weight,
                  fontFamily: fonts?.h1?.['font-family'],
                }}
            >
              {weight.name}
            </div>
        ))}
      </div>
    </div>
    <div className="flex-1">
      <div className="flex items-center gap-4 mb-4">
        <h3 className="text-neutral-800 text-sm font-normal leading-normal">
          Use for H2, H3, sub-headline, body text
        </h3>
      </div>
      <div
          className="grid grid-cols-3 md:grid-cols-2 lg:grid-cols-3 gap-5 flex-wrap">
        {fontWeightsTitle.map((weight) => (
            <div
                key={weight.name}
                className="text-xl font-inter font-semibold"
                style={{
                  fontWeight: weight.weight,
                  fontFamily: fonts?.h2?.['font-family'],
                }}
            >
              {weight.name}
            </div>
        ))}
      </div>
    </div>
  </div>
}