import React, {useEffect, useState} from 'react'

import {ProgressBar} from './components/ProgressBar'
import {api} from '../../services/api'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {getUser, setUser} from '../../services/utils'
import {ShowToast} from '../../services/toast'
import {pageView} from '../../services/mixpanel'
import {WelcomeCouch} from './components/WelcomeCouch'
import {BrandLookAndFeel} from './components/BrandLookAndFeel'
import {LastStep} from './components/LastStep'
import {OnboardingStepsEnum, stepsObject} from '../../types'

const steps: { [key: number]: any } = {
  1: WelcomeCouch,
  2: BrandLookAndFeel,
  3: LastStep,
}

const stepsCount = Object.keys(steps).length

export function Onboarding({onClose}: { onClose?: () => void }) {
  const [loading, setLoading] = useState<boolean>(false)
  const [params] = useSearchParams()
  const stepParam = params.get('step')
  const navigate = useNavigate()
  const [step, setStep] = useState<number>(1)
  const [data, setData] = useState<any>({})
  const [generatingPrompt, setGeneratingPrompt] = useState<boolean>(false)
  const [sectionData, setSectionData] = useState<any>(null)
  const [user, setUserInfo] = useState<any>({})

  const handleBack = () => {
    if (step === 1) return
    setStep(step - 1)
    navigate(`/onboarding?step=${step - 1}`)
  }

  const handleNext = (data?: any) => {
    // if (step === stepsCount) return;
    setStep(step + 1)
    if (step + 1 <= stepsCount) {
      const updatedUserData = {
        ...user,
        pendingOnboardingSection: Object.keys(stepsObject).find(
            (key) => stepsObject[key] === step + 1,
        ),
      }
      setUser(updatedUserData)
      setUserInfo(updatedUserData)
    }

    if (data) {
      setData((prev) => ({...prev, ...data}))
    }
    if (step + 1 <= stepsCount) {
      navigate(`/onboarding?step=${step + 1}`)
      pageView()
    }
  }

  const handleFinalStepOnSuccess = () => {
    const updatedUserData = {...user, isOnboardingCompleted: true}
    setUser(updatedUserData)
    setUserInfo(updatedUserData)
    navigate('/dashboard')
  }

  useEffect(() => {
    // on step 10 it should redirect to the dashboard after 3 seconds
    if (step === stepsCount + 1) {
      const updatedUserData = {...user, isOnboardingCompleted: true}
      setUser(updatedUserData)
      setUserInfo(updatedUserData)
      navigate('/dashboard')
      // setTimeout(() => {
      // }, 3000);
    }
  }, [step, navigate])

  useEffect(() => {
    api.getOnboardingSections().then((res) => {
      setSectionData(res);
    });
  }, []);


  useEffect(() => {
    const user = getUser()
    setUserInfo(user)
  }, [])

  useEffect(() => {
    if (!user._id) return
    api.getUserProfile(user._id).then((res) => {
      setData(res.onboardingSubmissions)
    }).catch((err) => {
      ShowToast({
        type: 'error',
        message: 'Failed to fetch user data',
      })
    })
  }, [user._id])

  useEffect(() => {
    if (!user._id) return
    if (user.pendingOnboardingSection === false) {
      // navigate('/dashboard');
    } else {
      if (
          !stepParam ||
          (stepParam &&
              stepsObject[user.pendingOnboardingSection] < Number(stepParam))
      ) {
        setStep(stepsObject[user.pendingOnboardingSection] || 1)
        navigate(
            `/onboarding?step=${stepsObject[user.pendingOnboardingSection] ||
            1}`,
        )
        pageView()
      } else {
        setStep(parseInt(stepParam))
      }
    }
    /**
     api
     .getSubscription(user._id)
     .then((subscriptionResponse) => {
        if (
          subscriptionResponse?.data?.length > 0 &&
          subscriptionResponse.data[0]?.status === "Paid"
        ) {
          if (user.pendingOnboardingSection === false) {
            // navigate('/dashboard');
          } else {
            if (
              !stepParam ||
              (stepParam &&
                stepsObject[user.pendingOnboardingSection] < Number(stepParam))
            ) {
              setStep(stepsObject[user.pendingOnboardingSection] || 1);
              navigate(
                `/onboarding?step=${
                  stepsObject[user.pendingOnboardingSection] || 1
                }`
              );
              pageView();
            } else {
              setStep(parseInt(stepParam));
            }
          }
        } else {
          navigate('/subscription');
        }
      })
     .catch((err) => {
        ShowToast({
          type: "error",
          message: "Failed to fetch subscription data",
        });
      });
     */
  }, [user.pendingOnboardingSection, user._id, navigate, stepParam])

  useEffect(() => {
    pageView()
  }, [])

  const Step = steps[step]
  console.log('Onboarding state', {sectionData, step})
  return sectionData && step && sectionData[step - 1] ? (
      <div
          className="fixed top-0 bottom-0 right-0 left-0 h-[100vh] flex flex-col bg-black bg-opacity-40">
        <div className="flex-1 bg-white pt-4 md:pt-8 flex flex-col">
          <div
              className="flex-1 max-w-screen-2xl w-full md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl 2xl:max-w-screen-2xl bg-white mx-auto px-5 md:px-0 pb-5 pt-7 flex flex-col">
            {!generatingPrompt && step <= stepsCount && (
                <ProgressBar total={stepsCount} progress={step}/>
            )}
            <div className="flex-1 flex flex-col justify-center items-center">
              {step <= stepsCount ? (
                  <Step
                      onBack={handleBack}
                      onNext={handleNext}
                      data={data}
                      metaData={sectionData[step - 1]}
                      stepCode={OnboardingStepsEnum[step]}
                      setGeneratingPrompt={setGeneratingPrompt}
                      generatingPrompt={generatingPrompt}
                      handleFinalStepOnSuccess={handleFinalStepOnSuccess}
                  />
              ) : null}
            </div>
          </div>
        </div>
      </div>
  ) : null
}
