import axios from "axios";

const instagramAPI = {
  getMedia: (token: any) => {
    return axios
      .get(
        `https://graph.instagram.com/me/media?fields=id,media_url,media_type,permalink&access_token=${token}`
      )
      .then((res) => res.data);
  },
  getLongLivedToken: (token: string) => {
    return axios.get(
      `https://graph.instagram.com/access_token?grant_type=ig_exchange_token&client_secret=7d718668b66441b6e919ee6759a15fad&access_token=${token}`
    );
  },
  revokeFBAuthPermissions: (token: string) => {
    return axios.delete(
      `https://graph.facebook.com/v18.0/me/permissions?access_token=${token}`
    );
  },
  getFBPageId: (token: string) => {
    return axios
      .get(`https://graph.facebook.com/v18.0/me/accounts?access_token=${token}`)
      .then((res) => res.data);
  },
  getInstagramBusinessAccount: (token: string, id: string) => {
    //https://graph.facebook.com/v18.0/134895793791914?fields=instagram_business_account&access_token={access-token}
    return axios
      .get(
        `https://graph.facebook.com/v18.0/${id}?fields=instagram_business_account&access_token=${token}`
      )
      .then((res) => res.data);
  },
  getInstagramProfile: (token: string, id: string) => {
    return axios
      .get(
        `https://graph.facebook.com/v18.0/${id}?fields=name,username,profile_picture_url,followers_count,follows_count,media_count,biography,website&access_token=${token}`
      )
      .then((res) => res.data);
  },
  getInstagramPosts: (token: string, id: string) => {
    // caption, type, media_url, permalink, timestamp, id,
    return axios
      .get(
        `https://graph.facebook.com/v18.0/${id}/media?fields=caption,media_type,media_url,permalink,timestamp,id,owner,thumbnail_url,username&access_token=${token}`
      )
      .then((res) => res.data);
  },
  searchLocation: (token: string, search: string) => {
    return axios.get(`https://graph.facebook.com/pages/search?q=${search}&fields=id,name,location,link&access_token=${token}`).then((res) => res.data);
  }
};

export default instagramAPI;
