import React, {useEffect, useRef, useState} from 'react'
import {Sketch} from '@uiw/react-color'

export const ColorInput = ({
                             onChange,
                             value,
                             title,
                           }: { onChange: (color: string) => void; value: string, title?: string }) => {
  const [open, setOpen] = useState(false)
  const ref = useRef(null)

  const handleChange = (color) => {
    onChange(color.hex)
  }

  // implement close on outside click
  useEffect(() => {
    // detect click outside
    document.addEventListener('click', (e) => {
      //@ts-expect-error
      if (ref.current && !ref.current.contains(e.target)) {
        setOpen(false)
      }
    })
  }, [])

  return (
      <div className="relative" ref={ref}>
        {title ? (<div
            className="text-black text-left my-2 text-lg font-semibold font-['Inter'] leading-normal">
          {title}
        </div>) : null}
        <button
            className="w-full md:w-48 h-20 relative z-[1]"
            onClick={() => {
              setOpen(!open)
            }}
        >
          <div className={'block'}>
            <div
                className="flex w-full md:w-48 h-20 rounded-lg items-end justify-start"
                style={{backgroundColor: value}}
            >
              <span
                  className="text-white p-3 text-lg font-semibold font-['Inter'] leading-normal">
                {value}
              </span>
            </div>

          </div>

        </button>
        {open && (
            <div className="absolute z-[2]">
              <Sketch color={value} onChange={handleChange}/>
            </div>
        )}
      </div>
  )
}
