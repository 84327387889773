import React, {useState} from 'react'

export type KeywordsParams = {
  keywords: string[]
  onSelected: (keywords: string[]) => void
  selectedKeywords?: string[]
}

export function Keywords(params: KeywordsParams) {
  const [allAvailableKeywords] = useState(params.keywords)
  const [selectedKeywords, setSelectedKeywords] = useState(
      params.selectedKeywords || [])
  const toggleSelected = (item: string) => {
    let selected: Array<string> = []
    if (selectedKeywords.includes(item)) {
      selected = selectedKeywords.filter((i) => i !== item)
      setSelectedKeywords(selected)
    } else {
      if (selectedKeywords.length < 5) {
        selected = [...selectedKeywords, item]
        setSelectedKeywords(selected)
      }
    }
    params.onSelected(selected)
  }

  return <div
      className="flex-1 md:p-5 grid grid-cols-[repeat(auto-fill,minmax(120px,full))] 2xl:grid-cols-10 xl:grid-cols-8 lg:grid-cols-6 md:grid-cols-5 sm:grid-cols-4 gap-5 md:bg-ultraLightGray">
    {allAvailableKeywords.map((item, i) => (
        <button
            onClick={() => toggleSelected(item)}
            className={`w-28 text-center px-3.5 py-2 bg-white rounded border ${
                selectedKeywords.includes(item) ?
                    'border-primary' :
                    'border-lightGray'
            }`}
            disabled={selectedKeywords.length === 5 &&
            !selectedKeywords.includes(item)}
            key={`${item}-${i}`}
        >
          <div
              className={`${
                  selectedKeywords.includes(item) ? 'text-black' : 'text-grey1'
              } text-sm font-medium leading-normal`}
          >
            {item}
          </div>
        </button>
    ))}
  </div>

}