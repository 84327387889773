/**
 * Generated by orval v6.15.0 🍺
 * Do not edit manually.
 * Guidely
 * The backend APIs
 * OpenAPI spec version: 1.0
 */
import useSwr from "swr";
import type { SWRConfiguration, Key } from "swr";
import type {
  GetTestimonialsDto,
  CreateTestimonialDto,
  UpdateTestimonialDto,
  TestimonialFindAllParams,
  UpdateMultipleTestimonialDto,
} from ".././schemas";
import { customInstance } from "../../customInstance";

export const testimonialCreate = (
  createTestimonialDto: CreateTestimonialDto,
) => {
  return customInstance<GetTestimonialsDto>({
    url: `/testimonials`,
    method: "post",
    headers: { "Content-Type": "application/json" },
    data: createTestimonialDto,
  });
};

export const testimonialUpdate = (
  updateTestimonialDto: UpdateTestimonialDto,
) => {
  return customInstance<GetTestimonialsDto>({
    url: `/testimonials`,
    method: "patch",
    headers: { "Content-Type": "application/json" },
    data: updateTestimonialDto,
  });
};

export const testimonialFindAll = (params?: TestimonialFindAllParams) => {
  return customInstance<GetTestimonialsDto[]>({
    url: `/testimonials`,
    method: "get",
    params,
  });
};

export const getTestimonialFindAllKey = (params?: TestimonialFindAllParams) =>
  [`/testimonials`, ...(params ? [params] : [])] as const;

export type TestimonialFindAllQueryResult = NonNullable<
  Awaited<ReturnType<typeof testimonialFindAll>>
>;
export type TestimonialFindAllQueryError = unknown;

export const useTestimonialFindAll = <TError = unknown>(
  params?: TestimonialFindAllParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof testimonialFindAll>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getTestimonialFindAllKey(params) : null));
  const swrFn = () => testimonialFindAll(params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

export const testimonialCreateMultiple = (
  createTestimonialDto: CreateTestimonialDto[],
) => {
  return customInstance<GetTestimonialsDto[]>({
    url: `/testimonials/multiple`,
    method: "post",
    headers: { "Content-Type": "application/json" },
    data: createTestimonialDto,
  });
};

export const testimonialUpdateMultiple = (
  updateMultipleTestimonialDto: UpdateMultipleTestimonialDto,
) => {
  return customInstance<void>({
    url: `/testimonials/multiple`,
    method: "patch",
    headers: { "Content-Type": "application/json" },
    data: updateMultipleTestimonialDto,
  });
};

export const testimonialFindOne = (id: string) => {
  return customInstance<GetTestimonialsDto>({
    url: `/testimonials/${id}`,
    method: "get",
  });
};

export const getTestimonialFindOneKey = (id: string) =>
  [`/testimonials/${id}`] as const;

export type TestimonialFindOneQueryResult = NonNullable<
  Awaited<ReturnType<typeof testimonialFindOne>>
>;
export type TestimonialFindOneQueryError = unknown;

export const useTestimonialFindOne = <TError = unknown>(
  id: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof testimonialFindOne>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!id;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getTestimonialFindOneKey(id) : null));
  const swrFn = () => testimonialFindOne(id);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

export const testimonialRemove = (id: string) => {
  return customInstance<void>({ url: `/testimonials/${id}`, method: "delete" });
};
