import apiClient from "./axios";
import {
  IBrandStyleColors, IBrandStyleTypography,
  IOnboardingStep,
  OnboardingStepsNamesEnum,
} from '../types'

export const api = {
  login: (data: any) => {
    return apiClient.post("/auth/login", data);
  },
  socialLogin: (data: any) => {
    return apiClient.post("/auth/login/social", data);
  },
  connectAyrshare: (userId: any) => {
    return apiClient.get(
      `/users/${userId}/social-connect-url?redirectUrl=${window.location.origin}/ayrshare?code1=success`
    );
  },
  connectStripe: (userId: any, tier: string, planType: string) => {
    return apiClient.get(
      `/users/${userId}/stripe-connect-url?tier=${tier}&planType=${planType}&redirectUrl=${window.location.origin}/stripe?code1=success`
    );
  },
  getSubscription: (userId: any) => {
    return apiClient.get(`/users/${userId}/subscriptions`, {timeout: 1000});
  },
  contentFeedback: (userId: any, data: any) => {
    return apiClient.post(`/users/${userId}/content-feedback`, data);
  },
  cancelSubscription: (userId: string, subscriptionId: string) => {
    return apiClient.delete(`/users/${userId}/subscriptions/${subscriptionId}`);
  },
  getInstagramProfile: (userId: any) => {
    return apiClient.get(`/users/${userId}/social-profile`);
  },
  getInstagramPosts: (userId: any) => {
    return apiClient.get(`/users/${userId}/social-posts`);
  },
  initEmailVerification: (id: any) => {
    return apiClient.post(`/users/${id}/email/verify/initiate`);
  },
  verifyEmail: (id: any, data: any) => {
    return apiClient.post(`/users/${id}/email/verify`, data);
  },
  signup: (data: any) => {
    return apiClient.post("/auth/register", data).then((res) => res.data);
  },
  logout: () => {
    return apiClient.post("/auth/logout").then((res) => res.data);
  },
  forgotPassword: (data: any) => {
    return apiClient.post("/auth/forgot-password", data);
  },
  resetPassword: (data: any) => {
    return apiClient.post("/auth/reset-password", data);
  },
  getOnboardingSections: () => {
    return apiClient.get("/onboarding_new_flow/sections").then((res) => res.data);
  },
  getOnboardingSection: (code: OnboardingStepsNamesEnum | string): Promise<IOnboardingStep> => {
    return apiClient
      .get(`/onboarding_new_flow/sections/${code}`)
      .then((res) => res.data);
  },
  saveOnboardingSection: (code: OnboardingStepsNamesEnum | string, data: any) => {
    return apiClient.put(`/onboarding_new_flow/sections/${code}`, data);
  },
  getBrandColors: (): Promise<IBrandStyleColors> => {
    return apiClient.get(`/onboarding_new_flow/colors`).then((res) => res.data);
  },
  getBrandTypography: (): Promise<IBrandStyleTypography> => {
    return apiClient.get(`/onboarding_new_flow/fonts`).then((res) => res.data);
  },
  refreshBrandColors: (): Promise<IBrandStyleColors> => {
    return apiClient.put(`/onboarding_new_flow/refresh/colors`).then((res) => res.data);
  },
  refreshBrandTypography: (): Promise<IBrandStyleTypography> => {
    return apiClient.put(`/onboarding_new_flow/refresh/typography`).then((res) => res.data);
  },
  getUserProfile: (id: string) => {
    return apiClient.get(`/users/${id}`).then((res) => res.data);
  },
  saveLastActive: (id: string) => {
    return apiClient.post(`/users/${id}/active`, {}, {timeout: 1000});
  },
  saveUserProfile: (id: string, data: any) => {
    return apiClient.patch(`/users/${id}`, data);
  },
  generatePrompt: (id: string, data: any, signal?: any) => {
    return apiClient.post(`/users/${id}/prompts`, data, { signal });
  },
  getBrandStyles: (id: string) => {
    return apiClient.get(`/users/${id}/brand-style`).then((res) => res.data);
  },
  regeneratePostsImage: (userId: string) => {
    // /users/{id}/regenerate-posts-image
    return apiClient.post(`/users/${userId}/regenerate-posts-image`);
  },
  saveBrandStyles: (id: string, data: any) => {
    return apiClient.patch(`/users/${id}/brand-style`, data);
  },
  getPosts: (userId: string) => {
    return apiClient.get(`/posts?userId=${userId}`).then((res) => res.data);
  },
  generateImage: (data: any) => {
    return apiClient.post(`/posts/${data._id}/image`, data);
  },
  createPost: (data) => {
    return apiClient.post(`/posts`, data);
  },
  updatePost: (id: string, data: any) => {
    return apiClient.patch(`/posts/${id}`, data);
  },
  getPost: (id: string) => {
    return apiClient.get(`/posts/${id}`).then((res) => res.data);
  },
  deletePost: (id: string) => {
    return apiClient.delete(`/posts/${id}`);
  },
  saveMedia: (data: any) => {
    return apiClient.post("/media", data);
  },
  searchMedia: (userId: string) => {
    return apiClient.get(`/media?userId=${userId}`).then((r) => r.data);
  },
  deleteMedia: (id: string) => {
    return apiClient.delete(`media/${id}`);
  },
  getProgressDashboard: (userId: string, month: string, year: string) => {
    return apiClient
      .get(`/users/${userId}/progress-dashboard?month=${month}&year=${year}`)
      .then((r) => r.data);
  },
  getDashboard: (userId: string) => {
    return apiClient.get(`/users/${userId}/dashboard`).then((r) => r.data);
  },
  getPredesignedUrl: ({
    id,
    mimeType,
    uploadType,
  }: {
    id: string;
    mimeType: string;
    uploadType: string;
  }) => {
    let url = `/users/${id}/images/signed-url?mimeType=${mimeType}&uploadType=${uploadType}`;

    return apiClient.get(url).then((r) => r.data);
  },
};
