import {ReactNode, HTMLAttributes, useState} from 'react'

import {useUserGuidesGetUserGuidesChecklist} from '../../apis/quidely/user-guides/user-guides'
import {LoadingScreen} from '../profile/LoadingScreen'
import {ShowToast} from '../../services/toast'
import {Checkbox} from '../../components/Checkbox/Checkbox'
import {UserGuidesModal, UserGuidesModalParams} from './UserGuidesModal'
import {CtaForm} from './CtaForm'
import {TestimonialForm} from './TestimonialsForm'
import {TopicIdeaForm} from './TopicsForm'
import {KeyedMutator} from 'swr'
import {UserGuidesDto} from '../../apis/quidely/schemas'

export type UserGuidesPageParams = {}

export type UserGuidesCheckListItemParams = {
  name: string
  isChecked: boolean
  headerText: string
  descriptionText: string
  refreshPromise?: KeyedMutator<UserGuidesDto>
}

export type UserGuidesItemsLayoutParams = {
  children: ReactNode
  className?: string
}

const UserGuidesItemsLayout = ({
                                 children,
                                 className,
                                 ...props
                               }: UserGuidesItemsLayoutParams & HTMLAttributes<HTMLDivElement>) => {
  return <div
      {...props}
      className={`bg-white rounded-lg border border-lightGray p-6 mb-6 ${className ??
      ''}`}
  >{children}</div>
}

const UserGuidesCheckListHeader = ({headerText}: { headerText: string }) => {
  return <div className={'flex py-2'}>
    <div style={{width: '72px'}} className="flex-none"/>
    <div className="grow justify-start content-start">
      <h2 className="text-base text-2xl font-semibold">{headerText}</h2>
    </div>
  </div>
}

const UserGuidesCheckListItem = ({
                                   name,
                                   isChecked,
                                   headerText,
                                   descriptionText,
                                   refreshPromise,
                                 }: UserGuidesCheckListItemParams) => {
  const [modalState, setModalState] = useState<UserGuidesModalParams | null>(
      null)
  const onClose = () => {
    setModalState(null)
  }

  const onSaved = async () => {
    console.log('Saved')
    if (refreshPromise) {
      await refreshPromise()
    }
  }

  const onClickHandler = (name) => () => {
    switch (name) {
      case 'cta':
        setModalState({
          onClose,
          formElement: (<CtaForm onSaved={onSaved}/>),
          open: true,
          title: 'Add Your Call-to-Action (CTA) Information',
          description: `Describe your offering(s) and what actions followers should take, such as sending you a DM or scheduling an introductory call.`,
        } as UserGuidesModalParams)
        break
      case 'testimonials':
        setModalState({
          onClose,
          formElement: (<TestimonialForm onSaved={onSaved}/>),
          open: true,
          title: 'Add Your Testimonials',
          description: `Add as many testimonials as you would like, highlighting the benefits that your clients have received from working with you.`,
        } as UserGuidesModalParams)
        break
      case 'topics':
        setModalState({
          onClose,
          formElement: (<TopicIdeaForm onSaved={onSaved}/>),
          open: true,
          title: 'Add Further Context For Informational Posts',
          description: `List any topics you would like to be covered in your informational posts, and any points that you would like mentioned.`,
        } as UserGuidesModalParams)
        break
      default:
    }
  }

  return (<div className={'flex-none py-2'}>
        <div className={'flex flex-row justify-start'}>
          <div className={'flex w-[72px] px-6'}>
            <Checkbox name={name} isChecked={isChecked} isReadOnly={true}/>
          </div>
          <div className={'grow justify-start content-start'}>
            <div>
              <h3 className={'text-base text-2xl font-semibold text-[#0091AE] hover:cursor-pointer'}
                  onClick={onClickHandler(name)}>{headerText}</h3>
            </div>
            <div>
              <p className="text-base">{descriptionText}</p>
            </div>
          </div>
          {modalState ?
              <UserGuidesModal {...modalState}/> :
              null}
        </div>
      </div>
  )
}

export const UserGuidesPage = (params: UserGuidesPageParams) => {
  const {data, isLoading, error, mutate} = useUserGuidesGetUserGuidesChecklist()

  if (isLoading) {
    return <LoadingScreen/>
  }
  if (error) {
    ShowToast({type: 'error', message: 'Something went wrong!'})
    return null
  }

  return <div className="relative bg-ultraLightGray p-4 md:p-6">
    <div className="flex flex-wrap justify-between gap-2 mb-8">
      <h1 className="text-neutral-800 text-4xl font-semibold leading-10">
        User Guides
      </h1>
    </div>
    <div>
      <UserGuidesItemsLayout>
        <UserGuidesCheckListHeader
            headerText={`High Priority: Optimize Your Posts`}/>
        <UserGuidesCheckListItem name={'cta'}
                                 isChecked={!!data?.isCtaExist}
                                 headerText={`Add Your Call-to-Action (CTA) Information`}
                                 refreshPromise={mutate}
                                 descriptionText={`Tell us what action(s) you want your followers to take, such as clicking on a link in your bio or sending you a DM.`}/>
      </UserGuidesItemsLayout>
      <UserGuidesItemsLayout>
        <UserGuidesCheckListHeader headerText={`Further Improve Your Posts `}/>
        <UserGuidesCheckListItem name={'testimonials'}
                                 isChecked={!!data?.isTestimonialsExist}
                                 headerText={`Add Testimonials`}
                                 refreshPromise={mutate}
                                 descriptionText={`The more testimonials you give us, the more they can be used to generate new posts.`}/>
        <UserGuidesCheckListItem name={'topics'}
                                 isChecked={!!data?.isTopicsExist}
                                 headerText={`Improve Your Informational Posts`}
                                 refreshPromise={mutate}
                                 descriptionText={`Let us know the topics that are most relevant to your coaching practice and your clients.`}/>
        {/*<UserGuidesCheckListItem name={'instagramCaptions'}*/}
        {/*                         isChecked={!!data?.isInstagramCaptionsExists}*/}
        {/*                         headerText={`Provide Instagram Caption Writing Samples`}*/}
        {/*                         refreshPromise={mutate}*/}
        {/*                         descriptionText={`We’ll capture your previous Instagram captions to improve our understanding of your writing style and brand voice.`}/>*/}
      </UserGuidesItemsLayout>
    </div>
  </div>

}