export const serifFonts = [
  'Abhaya Libre',
  'Abyssinica SIL',
  'Adamina',
  'Alegreya',
  'Alegreya SC',
  'Aleo',
  'Alice',
  'Alike',
  'Alike Angular',
  'Alkalami',
  'Almendra',
  'Almendra SC',
  'Amethysta',
  'Amiri',
  'Amiri Quran',
  'Andada Pro',
  'Annapurna SIL',
  'Antic Didone',
  'Antic Slab',
  'Aoboshi One',
  'Arapey',
  'Arbutus',
  'Arbutus Slab',
  'Aref Ruqaa',
  'Aref Ruqaa Ink',
  'Artifika',
  'Arvo',
  'Asar',
  'Average',
  'Bacasime Antique',
  'Balthazar',
  'Baskervville',
  'Baskervville SC',
  'Belgrano',
  'Bellefair',
  'Benne',
  'Bentham',
  'Besley',
  'Bevan',
  'BhuTuka Expanded One',
  'BioRhyme',
  'BioRhyme Expanded',
  'Bitter',
  'BIZ UDMincho',
  'BIZ UDPMincho',
  'Bodoni Moda',
  'Bodoni Moda SC',
  'Bona Nova',
  'Bona Nova SC',
  'Brawler',
  'Bree Serif',
  'Brygada 1918',
  'Buenard',
  'Cactus Classical Serif',
  'Caladea',
  'Cambo',
  'Cantata One',
  'Cardo',
  'Castoro',
  'Caudex',
  'Charis SIL',
  'Cinzel',
  'Copse',
  'Cormorant',
  'Cormorant Garamond',
  'Cormorant Infant',
  'Cormorant SC',
  'Cormorant Unicase',
  'Cormorant Upright',
  'Coustard',
  'Crete Round',
  'Crimson Pro',
  'Crimson Text',
  'Cutive',
  'Dai Banna SIL',
  'Danfo',
  'David Libre',
  'Della Respira',
  'Diphylleia',
  'DM Serif Display',
  'DM Serif Text',
  'Domine',
  'Donegal One',
  'EB Garamond',
  'Eczar',
  'Enriqueta',
  'Esteban',
  'Fanwood Text',
  'Fauna One',
  'Faustina',
  'Fenix',
  'Fjord One',
  'Frank Ruhl Libre',
  'Fraunces',
  'Gabriela',
  'Gelasio',
  'Gentium Book Basic',
  'Gentium Book Plus',
  'Gentium Plus',
  'GFS Didot',
  'Gilda Display',
  'Glegoo',
  'Gloock',
  'Goudy Bookletter 1911',
  'Gowun Batang',
  'Graduate',
  'Grandiflora One',
  'Grenze',
  'Gulzar',
  'Gupter',
  'Gurajada',
  'Habibi',
  'Hahmlet',
  'Halant',
  'Hanuman',
  'Headland One',
  'Hedvig Letters Serif',
  'Hepta Slab',
  'Hina Mincho',
  'Holtwood One SC',
  'Ibarra Real Nova',
  'IBM Plex Serif',
  'IM Fell Double Pica',
  'IM Fell Double Pica SC',
  'IM Fell DW Pica',
  'IM Fell DW Pica SC',
  'IM Fell English',
  'IM Fell English SC',
  'IM Fell French Canon',
  'IM Fell French Canon SC',
  'IM Fell Great Primer',
  'IM Fell Great Primer SC',
  'Imbue',
  'Inika',
  'Inknut Antiqua',
  'Inria Serif',
  'Instrument Serif',
  'Italiana',
  'Jacques Francois',
  'Joan',
  'Jomolhari',
  'Josefin Slab',
  'Judson',
  'Junge',
  'Kadwa',
  'Kaisei Decol',
  'Kaisei HarunoUmi',
  'Kaisei Opti',
  'Kaisei Tokumin',
  'Kalnia',
  'Kameron',
  'Karma',
  'Kay Pho Du',
  'Kiwi Maru',
  'Koh Santepheap',
  'Kotta One',
  'Kreon',
  'Kurale',
  'Labrada',
  'Lateef',
  'Ledger',
  'Libre Baskerville',
  'Libre Bodoni',
  'Libre Caslon Display',
  'Libre Caslon Text',
  'Linden Hill',
  'Lisu Bosa',
  'Literata',
  'Lora',
  'Lusitana',
  'Lustria',
  'Maiden Orange',
  'Maitree',
  'Maname',
  'Manuale',
  'Marcellus',
  'Marcellus SC',
  'Markazi Text',
  'Marko One',
  'Martel',
  'Mate',
  'Mate SC',
  'Merriweather',
  'Mirza',
  'Montaga',
  'Montagu Slab',
  'Namdhinggo',
  'Nanum Myeongjo',
  'Narnoor',
  'Neuton',
  'New Tegomin',
  'Newsreader',
  'Noticia Text',
  'Noto Naskh Arabic',
  'Noto Nastaliq Urdu',
  'Noto Rashi Hebrew',
  'Noto Serif',
  'Noto Serif Ahom',
  'Noto Serif Armenian',
  'Noto Serif Balinese',
  'Noto Serif Bengali',
  'Noto Serif Devanagari',
  'Noto Serif Display',
  'Noto Serif Dogra',
  'Noto Serif Ethiopic',
  'Noto Serif Georgian',
  'Noto Serif Grantha',
  'Noto Serif Gujarati',
  'Noto Serif Gurmukhi',
  'Noto Serif Hebrew',
  'Noto Serif HK',
  'Noto Serif JP',
  'Noto Serif Kannada',
  'Noto Serif Khitan Small Script',
  'Noto Serif Khmer',
  'Noto Serif Khojki',
  'Noto Serif KR',
  'Noto Serif Lao',
  'Noto Serif Makasar',
  'Noto Serif Malayalam',
  'Noto Serif Myanmar',
  'Noto Serif NP Hmong',
  'Noto Serif Old Uyghur',
  'Noto Serif Oriya',
  'Noto Serif Ottoman Siyaq',
  'Noto Serif SC',
  'Noto Serif Sinhala',
  'Noto Serif Tamil',
  'Noto Serif Tangut',
  'Noto Serif TC',
  'Noto Serif Telugu',
  'Noto Serif Thai',
  'Noto Serif Tibetan',
  'Noto Serif Toto',
  'Noto Serif Vithkuqi',
  'Noto Serif Yezidi',
  'Odor Mean Chey',
  'Old Standard TT',
  'Oranienbaum',
  'Ovo',
  'Padyakke Expanded One',
  'Peddana',
  'Peralta',
  'Petrona',
  'Piazzolla',
  'Platypi',
  'Playfair',
  'Playfair Display',
  'Playfair Display SC',
  'Podkova',
  'Poltawski Nowy',
  'Poly',
  'Port Lligat Slab',
  'Prata',
  'Pridi',
  'Prociono',
  'PT Serif',
  'PT Serif Caption',
  'Quando',
  'Quattrocento',
  'Radley',
  'Ramaraja',
  'Rasa',
  'Rhodium Libre',
  'Roboto Serif',
  'Roboto Slab',
  'Rokkitt',
  'Rosarivo',
  'Rozha One',
  'Rufina',
  'Ruwudu',
  'Sahitya',
  'Sanchez',
  'Sawarabi Mincho',
  'Scheherazade New',
  'Scope One',
  'Sedan',
  'Sedan SC',
  'Shippori Mincho',
  'Shippori Mincho B1',
  'Slabo 13px',
  'Slabo 27px',
  'Solway',
  'Song Myung',
  'Sorts Mill Goudy',
  'Source Serif 4',
  'Source Serif Pro',
  'Spectral',
  'Spectral SC',
  'Sree Krushnadevaraya',
  'Stint Ultra Condensed',
  'Stint Ultra Expanded',
  'STIX Two Text',
  'Stoke',
  'Suez One',
  'Sumana',
  'Sura',
  'Suranna',
  'Suravaram',
  'Suwannaphum',
  'Tai Heritage Pro',
  'Taviraj',
  'Texturina',
  'Tienne',
  'Tinos',
  'Tiro Bangla',
  'Tiro Devanagari Hindi',
  'Tiro Devanagari Marathi',
  'Tiro Devanagari Sanskrit',
  'Tiro Gurmukhi',
  'Tiro Kannada',
  'Tiro Tamil',
  'Tiro Telugu',
  'Trirong',
  'Trocchi',
  'Trykker',
  'Uchen',
  'Ultra',
  'Unna',
  'Vast Shadow',
  'Vesper Libre',
  'Vidaloka',
  'Volkhov',
  'Vollkorn',
  'Vollkorn SC',
  'Wellfleet',
  'Wittgenstein',
  'Young Serif',
  'Yrsa',
  'Yuji Boku',
  'Yuji Mai',
  'Yuji Syuku',
  'Zen Antique',
  'Zen Antique Soft',
  'Zen Old Mincho',
  'Zilla Slab',
  'Zilla Slab Highlight']

export const sansSerifFonts =
    [
      'ABeeZee',
      'Abel',
      'Aclonica',
      'Acme',
      'Actor',
      'Advent Pro',
      'Afacad',
      'Agdasima',
      'Akatab',
      'Akshar',
      'Alata',
      'Alatsi',
      'Albert Sans',
      'Aldrich',
      'Alef',
      'Alegreya Sans',
      'Alegreya Sans SC',
      'Alexandria',
      'Allerta',
      'Allerta Stencil',
      'Almarai',
      'Alumni Sans',
      'Alumni Sans Collegiate One',
      'Alumni Sans Pinstripe',
      'Amaranth',
      'Amiko',
      'Anaheim',
      'Andika',
      'Anek Bangla',
      'Anek Devanagari',
      'Anek Gujarati',
      'Anek Gurmukhi',
      'Anek Kannada',
      'Anek Latin',
      'Anek Malayalam',
      'Anek Odia',
      'Anek Tamil',
      'Anek Telugu',
      'Anta',
      'Antic',
      'Anton',
      'Anton SC',
      'Antonio',
      'Anuphan',
      'AR One Sans',
      'Archivo',
      'Archivo Black',
      'Archivo Narrow',
      'Arimo',
      'Armata',
      'Arsenal',
      'Arsenal SC',
      'Arya',
      'Asap',
      'Asap Condensed',
      'Assistant',
      'Asul',
      'Athiti',
      'Atkinson Hyperlegible',
      'Average Sans',
      'B612',
      'Bai Jamjuree',
      'Barlow',
      'Barlow Condensed',
      'Barlow Semi Condensed',
      'Basic',
      'Bayon',
      'Be Vietnam Pro',
      'Bebas Neue',
      'Beiruti',
      'Belanosima',
      'Belleza',
      'BenchNine',
      'Biryani',
      'BIZ UDGothic',
      'BIZ UDPGothic',
      'Black Han Sans',
      'Blinker',
      'Braah One',
      'Bricolage Grotesque',
      'Bubbler One',
      'Cabin',
      'Cabin Condensed',
      'Cagliostro',
      'Cairo',
      'Cairo Play',
      'Cambay',
      'Candal',
      'Cantarell',
      'Cantora One',
      'Capriola',
      'Carlito',
      'Carme',
      'Carrois Gothic',
      'Carrois Gothic SC',
      'Catamaran',
      'Chakra Petch',
      'Changa',
      'Chathura',
      'Chau Philomene One',
      'Chivo',
      'Chocolate Classical Sans',
      'Coda Caption',
      'Comme',
      'Commissioner',
      'Convergence',
      'Cuprum',
      'Darker Grotesque',
      'Days One',
      'Denk One',
      'Dhurjati',
      'Didact Gothic',
      'DM Sans',
      'Do Hyeon',
      'Dongle',
      'Doppio One',
      'Dorsa',
      'Dosis',
      'DotGothic16',
      'Duru Sans',
      'Economica',
      'El Messiri',
      'Electrolize',
      'Encode Sans',
      'Encode Sans Condensed',
      'Encode Sans Expanded',
      'Encode Sans SC',
      'Encode Sans Semi Condensed',
      'Encode Sans Semi Expanded',
      'Englebert',
      'Epilogue',
      'Exo',
      'Exo 2',
      'Fahkwang',
      'Familjen Grotesk',
      'Farro',
      'Federo',
      'Figtree',
      'Finlandica',
      'Fira Sans',
      'Fira Sans Condensed',
      'Fira Sans Extra Condensed',
      'Fjalla One',
      'Francois One',
      'Fredoka',
      'Fresca',
      'Fustat',
      'Gafata',
      'Galdeano',
      'Gantari',
      'Gasoek One',
      'Gayathri',
      'Gemunu Libre',
      'Genos',
      'Geo',
      'Geologica',
      'Georama',
      'GFS Neohellenic',
      'Gidugu',
      'Glory',
      'Golos Text',
      'Gothic A1',
      'Gotu',
      'Gowun Dodum',
      'Gruppo',
      'Gudea',
      'Hammersmith One',
      'Hanken Grotesk',
      'Harmattan',
      'Hedvig Letters Sans',
      'Heebo',
      'Hind',
      'Hind Guntur',
      'Hind Madurai',
      'Hind Siliguri',
      'Hind Vadodara',
      'Homenaje',
      'Hubballi',
      'IBM Plex Sans',
      'IBM Plex Sans Arabic',
      'IBM Plex Sans Condensed',
      'IBM Plex Sans Devanagari',
      'IBM Plex Sans Hebrew',
      'IBM Plex Sans JP',
      'IBM Plex Sans KR',
      'IBM Plex Sans Thai',
      'IBM Plex Sans Thai Looped',
      'Imprima',
      'Inclusive Sans',
      'Inder',
      'Inria Sans',
      'Instrument Sans',
      'Inter',
      'Inter Tight',
      'Istok Web',
      'Jaldi',
      'Jaro',
      'Jockey One',
      'Josefin Sans',
      'Jost',
      'Jua',
      'Julius Sans One',
      'Jura',
      'K2D',
      'Kanit',
      'Kantumruy',
      'Kantumruy Pro',
      'Karla',
      'Kdam Thmor Pro',
      'Khand',
      'Khmer',
      'Khula',
      'Kite One',
      'Kodchasan',
      'KoHo',
      'Kosugi',
      'Kosugi Maru',
      'Krona One',
      'Krub',
      'Kufam',
      'Kulim Park',
      'Kumbh Sans',
      'Laila',
      'Lato',
      'League Gothic',
      'League Spartan',
      'Lexend',
      'Lexend Deca',
      'Lexend Exa',
      'Lexend Giga',
      'Lexend Mega',
      'Lexend Peta',
      'Lexend Tera',
      'Lexend Zetta',
      'Libre Franklin',
      'Livvic',
      'Lunasima',
      'M PLUS 1',
      'M PLUS 1p',
      'M PLUS 2',
      'M PLUS Code Latin',
      'M PLUS Rounded 1c',
      'Mada',
      'Madimi One',
      'Magra',
      'Mako',
      'Mallanna',
      'Mandali',
      'Manjari',
      'Manrope',
      'Marmelad',
      'Martel Sans',
      'Marvel',
      'Maven Pro',
      'Meera Inimai',
      'Merriweather Sans',
      'Metrophobic',
      'Michroma',
      'Mina',
      'Mingzat',
      'Miriam Libre',
      'Mitr',
      'Mochiy Pop One',
      'Mochiy Pop P One',
      'Mohave',
      'Molengo',
      'Monda',
      'Monomaniac One',
      'Montserrat',
      'Montserrat Alternates',
      'Montserrat Subrayada',
      'Mooli',
      'Moulpali',
      'Mouse Memoirs',
      'Mukta',
      'Mukta Mahee',
      'Mukta Malar',
      'Mukta Vaani',
      'Mulish',
      'Murecho',
      'Nanum Gothic',
      'News Cycle',
      'Niramit',
      'Nobile',
      'Nokora',
      'Notable',
      'Noto Color Emoji',
      'Noto Emoji',
      'Noto Kufi Arabic',
      'Noto Music',
      'Noto Sans',
      'Noto Sans Adlam',
      'Noto Sans Adlam Unjoined',
      'Noto Sans Anatolian Hieroglyphs',
      'Noto Sans Arabic',
      'Noto Sans Armenian',
      'Noto Sans Avestan',
      'Noto Sans Balinese',
      'Noto Sans Bamum',
      'Noto Sans Bassa Vah',
      'Noto Sans Batak',
      'Noto Sans Bengali',
      'Noto Sans Bhaiksuki',
      'Noto Sans Brahmi',
      'Noto Sans Buginese',
      'Noto Sans Buhid',
      'Noto Sans Canadian Aboriginal',
      'Noto Sans Carian',
      'Noto Sans Caucasian Albanian',
      'Noto Sans Chakma',
      'Noto Sans Cham',
      'Noto Sans Cherokee',
      'Noto Sans Chorasmian',
      'Noto Sans Coptic',
      'Noto Sans Cuneiform',
      'Noto Sans Cypriot',
      'Noto Sans Cypro Minoan',
      'Noto Sans Deseret',
      'Noto Sans Devanagari',
      'Noto Sans Display',
      'Noto Sans Duployan',
      'Noto Sans Egyptian Hieroglyphs',
      'Noto Sans Elbasan',
      'Noto Sans Elymaic',
      'Noto Sans Ethiopic',
      'Noto Sans Georgian',
      'Noto Sans Glagolitic',
      'Noto Sans Gothic',
      'Noto Sans Grantha',
      'Noto Sans Gujarati',
      'Noto Sans Gunjala Gondi',
      'Noto Sans Gurmukhi',
      'Noto Sans Hanifi Rohingya',
      'Noto Sans Hanunoo',
      'Noto Sans Hatran',
      'Noto Sans Hebrew',
      'Noto Sans HK',
      'Noto Sans Imperial Aramaic',
      'Noto Sans Indic Siyaq Numbers',
      'Noto Sans Inscriptional Pahlavi',
      'Noto Sans Inscriptional Parthian',
      'Noto Sans Javanese',
      'Noto Sans JP',
      'Noto Sans Kaithi',
      'Noto Sans Kannada',
      'Noto Sans Kawi',
      'Noto Sans Kayah Li',
      'Noto Sans Kharoshthi',
      'Noto Sans Khmer',
      'Noto Sans Khojki',
      'Noto Sans Khudawadi',
      'Noto Sans KR',
      'Noto Sans Lao',
      'Noto Sans Lao Looped',
      'Noto Sans Lepcha',
      'Noto Sans Limbu',
      'Noto Sans Linear A',
      'Noto Sans Linear B',
      'Noto Sans Lisu',
      'Noto Sans Lycian',
      'Noto Sans Lydian',
      'Noto Sans Mahajani',
      'Noto Sans Malayalam',
      'Noto Sans Mandaic',
      'Noto Sans Manichaean',
      'Noto Sans Marchen',
      'Noto Sans Masaram Gondi',
      'Noto Sans Math',
      'Noto Sans Mayan Numerals',
      'Noto Sans Medefaidrin',
      'Noto Sans Meetei Mayek',
      'Noto Sans Mende Kikakui',
      'Noto Sans Meroitic',
      'Noto Sans Miao',
      'Noto Sans Modi',
      'Noto Sans Mongolian',
      'Noto Sans Mono',
      'Noto Sans Mro',
      'Noto Sans Multani',
      'Noto Sans Myanmar',
      'Noto Sans Nabataean',
      'Noto Sans Nag Mundari',
      'Noto Sans Nandinagari',
      'Noto Sans New Tai Lue',
      'Noto Sans Newa',
      'Noto Sans NKo',
      'Noto Sans NKo Unjoined',
      'Noto Sans Nushu',
      'Noto Sans Ogham',
      'Noto Sans Ol Chiki',
      'Noto Sans Old Hungarian',
      'Noto Sans Old Italic',
      'Noto Sans Old North Arabian',
      'Noto Sans Old Permic',
      'Noto Sans Old Persian',
      'Noto Sans Old Sogdian',
      'Noto Sans Old South Arabian',
      'Noto Sans Old Turkic',
      'Noto Sans Oriya',
      'Noto Sans Osage',
      'Noto Sans Osmanya',
      'Noto Sans Pahawh Hmong',
      'Noto Sans Palmyrene',
      'Noto Sans Pau Cin Hau',
      'Noto Sans Phags Pa',
      'Noto Sans Phoenician',
      'Noto Sans Psalter Pahlavi',
      'Noto Sans Rejang',
      'Noto Sans Runic',
      'Noto Sans Samaritan',
      'Noto Sans Saurashtra',
      'Noto Sans SC',
      'Noto Sans Sharada',
      'Noto Sans Shavian',
      'Noto Sans Siddham',
      'Noto Sans SignWriting',
      'Noto Sans Sinhala',
      'Noto Sans Sogdian',
      'Noto Sans Sora Sompeng',
      'Noto Sans Soyombo',
      'Noto Sans Sundanese',
      'Noto Sans Syloti Nagri',
      'Noto Sans Symbols',
      'Noto Sans Symbols 2',
      'Noto Sans Syriac',
      'Noto Sans Syriac Eastern',
      'Noto Sans Tagalog',
      'Noto Sans Tagbanwa',
      'Noto Sans Tai Le',
      'Noto Sans Tai Tham',
      'Noto Sans Tai Viet',
      'Noto Sans Takri',
      'Noto Sans Tamil',
      'Noto Sans Tamil Supplement',
      'Noto Sans Tangsa',
      'Noto Sans TC',
      'Noto Sans Telugu',
      'Noto Sans Thaana',
      'Noto Sans Thai',
      'Noto Sans Thai Looped',
      'Noto Sans Tifinagh',
      'Noto Sans Tirhuta',
      'Noto Sans Ugaritic',
      'Noto Sans Vai',
      'Noto Sans Vithkuqi',
      'Noto Sans Wancho',
      'Noto Sans Warang Citi',
      'Noto Sans Yi',
      'Noto Sans Zanabazar Square',
      'Noto Traditional Nushu',
      'Noto Znamenny Musical Notation',
      'NTR',
      'Numans',
      'Nunito',
      'Nunito Sans',
      'Nuosu SIL',
      'Ojuju',
      'Onest',
      'Open Sans',
      'Orbit',
      'Orbitron',
      'Orienta',
      'Oswald',
      'Outfit',
      'Overpass',
      'Oxygen',
      'Padauk',
      'Palanquin',
      'Palanquin Dark',
      'Pathway Extreme',
      'Pathway Gothic One',
      'Pattaya',
      'Pavanam',
      'Paytone One',
      'Philosopher',
      'Play',
      'Plus Jakarta Sans',
      'Pontano Sans',
      'Poppins',
      'Port Lligat Sans',
      'Pragati Narrow',
      'Preahvihear',
      'Prompt',
      'Proza Libre',
      'PT Sans',
      'PT Sans Caption',
      'PT Sans Narrow',
      'Public Sans',
      'Puritan',
      'Qahiri',
      'Quantico',
      'Quattrocento Sans',
      'Questrial',
      'Quicksand',
      'Radio Canada',
      'Radio Canada Big',
      'Rajdhani',
      'Raleway',
      'Ramabhadra',
      'Rambla',
      'Rationale',
      'Readex Pro',
      'Recursive',
      'Red Hat Display',
      'Red Hat Text',
      'Reddit Sans',
      'Reddit Sans Condensed',
      'Reem Kufi',
      'Reem Kufi Fun',
      'Reem Kufi Ink',
      'REM',
      'Rethink Sans',
      'Roboto',
      'Roboto Condensed',
      'Roboto Flex',
      'RocknRoll One',
      'Ropa Sans',
      'Rosario',
      'Rubik',
      'Rubik Mono One',
      'Ruda',
      'Ruluko',
      'Rum Raisin',
      'Russo One',
      'Saira',
      'Saira Condensed',
      'Saira Extra Condensed',
      'Saira Semi Condensed',
      'Sankofa Display',
      'Sansita',
      'Sarabun',
      'Sarala',
      'Sarpanch',
      'Sawarabi Gothic',
      'Scada',
      'Schibsted Grotesk',
      'Secular One',
      'Sen',
      'Seymour One',
      'Shanti',
      'Share',
      'Share Tech',
      'Shippori Antique',
      'Shippori Antique B1',
      'Siemreap',
      'Signika',
      'Signika Negative',
      'Sintony',
      'Six Caps',
      'Smooch Sans',
      'Snippet',
      'Sofia Sans',
      'Sofia Sans Condensed',
      'Sofia Sans Extra Condensed',
      'Sofia Sans Semi Condensed',
      'Sono',
      'Sora',
      'Source Sans 3',
      'Source Sans Pro',
      'Space Grotesk',
      'Spinnaker',
      'Spline Sans',
      'Stick',
      'Stick No Bills',
      'Strait',
      'Stylish',
      'Sulphur Point',
      'Sunflower',
      'Syncopate',
      'Syne',
      'Tac One',
      'Tajawal',
      'Tauri',
      'Teachers',
      'Teko',
      'Telex',
      'Tenali Ramakrishna',
      'Tenor Sans',
      'Text Me One',
      'Thasadith',
      'Timmana',
      'Tiny5',
      'Titillium Web',
      'Tomorrow',
      'Trispace',
      'Truculenta',
      'Tsukimi Rounded',
      'Ubuntu',
      'Ubuntu Condensed',
      'Ubuntu Sans',
      'Unbounded',
      'Urbanist',
      'Varela',
      'Varela Round',
      'Varta',
      'Vazirmatn',
      'Viga',
      'Voces',
      'Voltaire',
      'Wendy One',
      'Wire One',
      'Wix Madefor Display',
      'Wix Madefor Text',
      'Work Sans',
      'Yaldevi',
      'Yanone Kaffeesatz',
      'Yantramanav',
      'Ysabeau',
      'Ysabeau Infant',
      'Ysabeau Office',
      'Ysabeau SC',
      'Yusei Magic',
      'Zain',
      'ZCOOL KuaiLe',
      'ZCOOL QingKe HuangYou',
      'ZCOOL XiaoWei',
      'Zen Kaku Gothic Antique',
      'Zen Kaku Gothic New',
      'Zen Kurenaido',
      'Zen Maru Gothic']
