import React, {
  createContext,
  ReactNode, SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'
import TagManager from 'react-gtm-module'
import {isURL} from 'class-validator'

import {IOnboardingStepMap, OnboardingStepsNamesEnum} from '../../types'
import {getUser} from '../../services/utils'

import {fileUpload} from '../../services/file-upload'
import {api} from '../../services/api'
import {GTM_ID} from '../../constant'
import {ShowToast} from '../../services/toast'
import {
  JOURNEY_MAX_LENGTH, JOURNEY_MAX_LENGTH_MESSAGE,
  JOURNEY_MIN_LENGTH,
  JOURNEY_MIN_LENGTH_MESSAGE, WEBSITE_URL_MESSAGE,
} from './BusinessInfo.constants'

export type BusinessInfoFormType = {
  journey: string
  website_url?: string
}

export type BusinessInfoContextType = {
  loading: boolean
  promptLoading: boolean
  urls: string[]
  images: File[]
  errors: Record<string, string>
  form: BusinessInfoFormType
  currentStepData: IOnboardingStepMap | null
  generatingPrompt: boolean
  handleNext: (e: React.MouseEvent<HTMLButtonElement>) => Promise<void>
  setErrors: (errors: SetStateAction<Record<string, string>>) => void
  setForm: (input: SetStateAction<Record<string, any>>) => void
  setImages: (input: SetStateAction<File[]>) => void
  setUrls: (input: SetStateAction<string[]>) => void
  onSaved: (params: any) => void
}
export const BusinessInfoContextDefaults: BusinessInfoContextType = {
  images: [],
  errors: {},
  form: {journey: '', website_url: ''},
  loading: false,
  promptLoading: false,
  urls: [],
  currentStepData: null,
  generatingPrompt: false,
  setErrors(): void {
  },
  setForm(input: Record<string, any>): void {
  },
  setImages(input: SetStateAction<File[]>): void {
  },
  setUrls(input: SetStateAction<string[]>): void {
  },
  onSaved(params: any): void {
  },
  handleNext(e: React.MouseEvent<HTMLButtonElement>): Promise<void> {
    return Promise.resolve()
  },
}, BusinessInfoContext = createContext<BusinessInfoContextType>(
    BusinessInfoContextDefaults)
export type BusinessInfoProviderParams = {
  stepCode: OnboardingStepsNamesEnum
  onSaved: (params: any) => void
  children: ReactNode
  setGeneratingPrompt?: (value: boolean) => void;
  generatingPrompt: boolean;
}

export const convertImagesToUrls = (input: File[]) => {
  return Array.from(input)
      .map((file) => URL.createObjectURL(file))
}

export const BusinessInfoProvider = ({
                                       stepCode,
                                       onSaved,
                                       children,
                                       setGeneratingPrompt,
                                       generatingPrompt,
                                     }: BusinessInfoProviderParams) => {
  const user = getUser()
  const [loading, setLoading] = useState<boolean>(false)
  const [currentStepData, setCurrentStepData] = useState<IOnboardingStepMap | null>(
      null)
  const [promptLoading, setPromptLoading] = React.useState<boolean>(false)
  const [form, setForm] = React.useState<any>(BusinessInfoContextDefaults.form)
  const [images, setImages] = React.useState<File[]>([])
  const [urls, setUrls] = useState<string[]>([])
  const [errors, setErrors] = useState<Record<string, string>>({})

  const handleNext = async (e: React.MouseEvent<HTMLButtonElement>) => {
    const currentErrors: Record<string, string> = {}
    if (form.journey && images.length) {
      if (form.journey.length < JOURNEY_MIN_LENGTH) {
        currentErrors.journey = JOURNEY_MIN_LENGTH_MESSAGE
      } else if (form.journey.length > JOURNEY_MAX_LENGTH) {
        currentErrors.journey = JOURNEY_MAX_LENGTH_MESSAGE
      }
      if (form.website_url && !isURL(form.website_url)) {
        currentErrors.website_url = WEBSITE_URL_MESSAGE
      }
      if (Object.keys(currentErrors).length) {
        setErrors(currentErrors)
        return
      }
      try {
        setLoading(true)
        // upload all images
        const imageList: string[] = []
        const promises: Promise<{ url: string }>[] = []
        for (let i = 0; i < images.length; i++) {
          const image = images[i]
          promises.push(fileUpload(
              image,
              user._id,
              'USER_IMAGE',
          ))
        }
        const results = await Promise.all(promises)
        if (results.length) {
          results.forEach(item => {
            imageList.push(item.url)
          })
        }
        const response = await api.saveOnboardingSection(
            OnboardingStepsNamesEnum.LAST_STEP, {
              headshots: imageList,
              journey: form.journey,
              website_url: form.website_url || undefined,
            })
        setLoading(false)
        if (response.status === 200) {
          // TODO: When we need to return Screen with video
          // if (setGeneratingPrompt) {
          //   setGeneratingPrompt(true)
          // }
          // // const timer = 1000 * 60 * 2
          // const timer = 1000 * 20
          // setTimeout(() => {
          //   setPromptLoading(false)
          // }, timer)
          const tagManagerArgs = {
            gtmId: GTM_ID,
            dataLayer: {
              event: 'onboardingCompleted',
              userId: user._id,
              email: user.email,
            },
          }
          if (TagManager.dataLayer) {
            TagManager.dataLayer(tagManagerArgs)
          }
          onSaved({})
        }
      } catch (err) {
        ShowToast({message: 'Failed to save answers', type: 'error'})
        setLoading(false)
        if (setGeneratingPrompt) {
          setGeneratingPrompt(false)
        }
        setPromptLoading(false)
      }
    } else {
      ShowToast({
        message: 'Please fill required fields marked with red *',
        type: 'error',
      })
    }
  }

  useEffect(() => {
    if (!currentStepData && !loading) {
      setLoading(true)
      api.getOnboardingSection(OnboardingStepsNamesEnum[stepCode])
          .then(result => {
            const questions = {}
            result.questions.forEach((item) => {
              questions[item.fieldName] = item
              if (item.fieldName === 'headshots' && item.answer &&
                  Array.isArray(item.answer) && item.answer.length) {
                const imagePromises: Promise<BlobPart>[] = []
                item.answer.forEach(url => {
                  imagePromises.push(
                      fetch(url, {
                        mode: 'cors',
                        cache: 'no-cache',
                        referrerPolicy: 'unsafe-url',
                      }).then(r => r.blob()))
                })

                Promise.all(imagePromises).then(imagesBlobs => {
                  const inputImages = imagesBlobs.map(
                      (blob, index) => {
                        const fileName = item.answer[index].split('/')
                            .reverse()[0]
                        return new File([blob], fileName)
                      })
                  setImages(inputImages)
                  setUrls(convertImagesToUrls(inputImages))
                })
              }
            })
            const currentStepsData = {...result, questions}
            setCurrentStepData(currentStepsData)
            setForm({
              journey: questions['journey']?.answer,
              website_url: questions['website_url']?.answer,
            })
            setLoading(false)
          }).catch(error => {
        console.error(error)
        setLoading(false)
      })
    }
  }, [currentStepData, setCurrentStepData, stepCode, loading])

  useEffect(() => {
    if (currentStepData) {
      const keys = Object.keys(currentStepData.questions)
      const initialFormData = {}
      keys.forEach(fieldName => {
        initialFormData[fieldName] = currentStepData.questions[fieldName]?.answer
      })
      setForm(initialFormData)
    }
  }, [currentStepData])

  const values = {
    loading,
    promptLoading,
    urls,
    errors,
    form,
    currentStepData,
    images,
    setErrors,
    setForm,
    generatingPrompt,
    setImages,
    setUrls,
    onSaved,
    handleNext,
  }
  return <BusinessInfoContext.Provider children={children} value={values}/>

}

export function useBusinessInfoContext() {
  const context = useContext(BusinessInfoContext)
  if (context === undefined) {
    throw new Error(
        'useBusinessInfoContext must be used within a BusinessInfoProvider')
  }
  return context
}
