import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
  ReactNode,
} from 'react'
import {
  IBrandStyle,
  IBrandStyleColors,
  IBrandStyleTypography,
  IOnboardingStepMap,
  OnboardingStepsNamesEnum,
} from '../../types'
import {api} from '../../services/api'

export type LAFContextType = {
  colors: string[]
  fonts?: IBrandStyleTypography
  currentStepData: IOnboardingStepMap | null
  loading: boolean
  handleNext: () => Promise<void>
  onRefreshColor: () => void
  onRefreshTypography: () => void
  onUpdateBrandColors: (colors: string[]) => void
  onUpdateBrandFonts: (request: IBrandStyleTypography) => void
}

export const LAFContext = createContext<LAFContextType>({
  colors: [],
  currentStepData: null,
  loading: false,
  handleNext: () => Promise.resolve(),
  onRefreshColor: () => {
  },
  onRefreshTypography: () => {
  },
  onUpdateBrandColors: (colors: string[]) => {
  },
  onUpdateBrandFonts: (request: IBrandStyleTypography) => {
  },
})

export type LAFProviderProps = {
  stepCode: OnboardingStepsNamesEnum
  onSaved: (params: any) => void
  children: ReactNode
}

export function LAFProvider({children, stepCode, onSaved}: LAFProviderProps) {
  const [loading, setLoading] = useState<boolean>(false)
  const [currentStepData, setCurrentStepData] = useState<IOnboardingStepMap | null>(
      null)
  const [colors, setColors] = useState<string[]>([])
  const [fonts, setFonts] = useState<IBrandStyleTypography>()

  const colorsOrder = useMemo(() => [
    'primary',
    'darkSecondary',
    'lightSecondary',
    'accent',
    'neutral',
  ], [])

  const handleNext = async () => {
    if (currentStepData && currentStepData.questions['colors'] &&
        currentStepData.questions['colors']?.answer && fonts) {
      const request: IBrandStyle = {
        brandStyleColors: currentStepData.questions['colors'].answer as IBrandStyleColors,
        brandStyleTypography: fonts,
      }
      setLoading(true)
      const response = await api.saveOnboardingSection(
          OnboardingStepsNamesEnum.BRAND_LAF, request)
      setLoading(false)
      if (response.status === 200) {
        onSaved({})
      }
    }
  }

  const updateColorsState = (brandStyleColors: IBrandStyleColors) => {
    setCurrentStepData((prevState) => {
      return prevState ? {
        ...prevState,
        questions: {
          ...prevState?.questions,
          colors: {
            ...prevState?.questions?.colors,
            answer: brandStyleColors,
          },
        },
      } : null
    })
    const brandColors = colorsOrder.map(colorName => {
      return brandStyleColors[colorName].code
    })
    setColors(brandColors)
  }

  const onRefreshColor = () => {
    setColors([])
    api.refreshBrandColors().then(result => {
      updateColorsState(result)
    }).catch((errors) => {
      console.error(errors)
    })
  }

  const onRefreshTypography = () => {
    setFonts(undefined)
    api.refreshBrandTypography().then(result => {
      console.log({result})
      setFonts(result)
    }).catch((errors) => {
      console.error(errors)
    })
  }

  useEffect(() => {
    if (!currentStepData && !loading) {
      setLoading(true)
      api.getOnboardingSection(OnboardingStepsNamesEnum[stepCode])
          .then(result => {
            console.log({result})
            const questions = {}
            result.questions.forEach((item) => {
              questions[item.fieldName] = item
              if (item.fieldName === 'colors') {
                const brandColors = colorsOrder.map(colorName => {
                  return questions[item.fieldName].answer[colorName].code
                })
                setColors(brandColors)
              }
              if (item.fieldName === 'fonts') {
                setFonts(questions[item.fieldName].answer)
              }
            })
            const currentStepsData = {...result, questions}
            setCurrentStepData(currentStepsData)
            setLoading(false)
          }).catch(error => {
        console.error(error)
        setLoading(false)
      })
    }
  }, [
    currentStepData,
    setCurrentStepData,
    stepCode,
    loading,
    setLoading,
    colorsOrder])

  const convertColorsListToObject = (colors: string[]) => {
    const request: Partial<IBrandStyleColors> = {}
    colorsOrder.forEach((colorName, index) => {
      request[colorName] = {
        code: colors[index],
      }
    })
    return request
  }

  const onUpdateBrandColors = (colors: string[]) => {
    const request = convertColorsListToObject(colors)
    return api.saveOnboardingSection(OnboardingStepsNamesEnum.BRAND_LAF,
        {brandStyleColors: request})
        .then(() => {
          return api.getBrandColors()
        })
        .then((brandStyleColors) => {
          updateColorsState(brandStyleColors)
          return colors
        }).catch(errors => {
          console.error(errors)
        })
  }

  const onUpdateBrandFonts = (request: IBrandStyleTypography) => {
    return api.saveOnboardingSection(OnboardingStepsNamesEnum.BRAND_LAF,
        {brandStyleTypography: request})
        .then(() => {
          return api.getBrandTypography()
        })
        .then((brandStyleTypography) => {
          setFonts(brandStyleTypography)
          return brandStyleTypography
        }).catch(errors => {
          console.error(errors)
        })
  }

  const values = {
    colors,
    fonts,
    currentStepData,
    loading,
    handleNext,
    onRefreshColor,
    onRefreshTypography,
    onUpdateBrandColors,
    onUpdateBrandFonts,
  }

  return <LAFContext.Provider value={values}>{children}</LAFContext.Provider>
}

export function useLAFContext() {
  const context = useContext(LAFContext)
  if (context === undefined) {
    throw new Error('useLAFContext must be used within a LAFProvider')
  }
  return context
}
