import React, {useEffect, useState} from 'react'

import {MainUserProfile} from '../../components/MainUserProfile'
import {
  MainUserProfileContext,
  MainUserProfileProvider,
} from '../../components/MainUserProfile/MainUserProfile.context'
import {OnboardingStepsNamesEnum} from '../../types'
import {Button} from '../../components/Button'
import {LAFContext, LAFProvider, LookAndFeel} from '../../components/LAF'
import {FontsStyle} from './components/FontsStyle'
import {Accordion} from '../../components/Accordion'
import {
  BusinessInfoContext,
  BusinessInfoProvider,
  BusinessInfo,
} from '../../components/BusinessInfo'
import {fileUpload} from '../../services/file-upload'
import {api} from '../../services/api'
import {useUser} from './context/user-context'
import userImage from '../../assets/user.png'
import {Loading} from '../../components/Loading/Loading'
import {ReactComponent as InstagramIcon} from '../../assets/instagram.svg'
import {getUser} from '../../services/utils'
import {LoadingScreen} from './LoadingScreen'

export const UserProfile = () => {
  const profilePicRef = React.useRef<HTMLInputElement>(null)
  const [activeKey, setActiveKey] = useState(0)
  const [profilePicLoading, setProfilePicLoading] =
      React.useState<boolean>(false)
  const [fetching, setFetching] = useState<boolean>(false)
  const {fetchUser} = useUser() as any
  const [profile, setProfile] = useState<any>({})
  const user = getUser()
  const details = profile?.onboardingSubmissions

  const handleProfilePicChange = async (file: File) => {
    setProfilePicLoading(true)
    const uploadResponse = await fileUpload(file, user._id, 'USER_THUMBNAIL')
    if (uploadResponse.url) {
      const response = await api.saveUserProfile(user._id, {
        profilePic: uploadResponse.url,
      })
      console.log({response})
      if ([200, 201].includes(response.status)) {
        fetchUser(user._id)
        setProfile((prev) => ({
          ...prev,
          profilePic: uploadResponse.url,
        }))
      }
    }
    setProfilePicLoading(false)
  }

  const fetchProfile = React.useCallback(() => {
    setFetching(true)
    api.getUserProfile(user._id).then((response) => {
      setProfile(response)
      setFetching(false)
    })
  }, [user._id])

  useEffect(() => {
    // call api to get profile data, get id from localstorage
    if (!Object.keys(profile).length && !fetching) {
      fetchProfile()
    }
  }, [profile, fetching, fetchProfile])

  if (fetching) {
    return <LoadingScreen/>
  }

  return <div className="relative bg-ultraLightGray p-4 md:p-6">
    <div className="flex flex-wrap justify-between gap-2 mb-8">
      <h1 className="text-neutral-800 text-4xl font-semibold leading-10">
        User Profile
      </h1>
    </div>
    <div
        className="p-4 md:p-6 bg-white rounded-lg shadow items-center gap-7 flex w-full">
      <div
          className="w-[72px] h-[72px] md:w-28 md:h-28 bg-white overflow-hidden relative rounded-full flex-col justify-start items-start flex">
        <img
            src={
              profile?.instagramProfilePic ||
              profile?.profilePic ||
              details?.IMAGES?.[0] ||
              userImage
            }
            alt="profile-pic"
            className="w-full h-full object-cover rounded-full"
        />
        {profilePicLoading && (
            <div
                className="absolute top-0 bottom-0 left-0 right-0 bg-black/30 flex justify-center items-center [&>svg]:mr-0 [&>svg]:w-6 [&>svg]:h-6">
              <Loading/>
            </div>
        )}
        <button
            onClick={() => profilePicRef.current?.click()}
            className="hidden md:block text-center bg-black bg-opacity-50 absolute bottom-0 left-0 right-0 h-8 flex items-center justify-center"
            disabled={profilePicLoading}
        >
              <span
                  className="text-white text-sm font-normal font-['Inter'] leading-normal">
                Edit
              </span>
        </button>
        <input
            type="file"
            className="hidden"
            ref={profilePicRef}
            accept="image/*"
            onChange={(e) => {
              const files = e.target.files
              if (files?.length) {
                handleProfilePicChange(files[0])
              }
              e.target.value = ''
            }}
        />
      </div>
      <div className="flex-col justify-start items-start gap-2.5 flex">
        <div className="text-neutral-800 text-2xl font-bold font-['Inter']">
          {details?.DISPLAY_NAME}
        </div>
        <div className="justify-start items-center gap-2 flex">
          <InstagramIcon className="w-5 h-5"/>
          <div className="text-gray-600 text-base font-normal font-['Inter']">
            {typeof details?.INSTAGRAM_HANDLE === 'string'
                ? '@' + details?.INSTAGRAM_HANDLE
                : details?.INSTAGRAM_HANDLE?.username}
          </div>
        </div>
      </div>
    </div>
    <div className="bg-white rounded-lg border border-lightGray p-6 mb-6">
      <Accordion activeKey={activeKey}
                 onSelect={(eventKey) => setActiveKey(eventKey as number)}
                 bordered defaultActiveKey={0}>
        <Accordion.Panel header={'Main User data'}
                         eventKey={1}>
          {activeKey === 1 ?
              <MainUserProfileProvider
                  stepCode={OnboardingStepsNamesEnum.WELCOME_COACH}
                  onSaved={() => {
                    setProfile({})
                  }}>
                <MainUserProfileContext.Consumer>
                  {({handleNext, loading, form}) => {
                    const disabled = !(form.displayName &&
                        form.instagramHandle &&
                        form?.keywords?.length)
                    return <>
                      <MainUserProfile isExitButtonEnabled={false}
                                       isHeadlineAllowed={false}/>
                      <div className="my-8 border-t border-lightGray"></div>
                      <Button
                          title={'Save'}
                          variant={'primary'}
                          onClick={handleNext}
                          disabled={disabled}
                          loading={loading}
                      >Save</Button>
                    </>
                  }}
                </MainUserProfileContext.Consumer>
              </MainUserProfileProvider>
              :
              null}
        </Accordion.Panel>
        <Accordion.Panel header="Look and feel"
                         eventKey={2}
        >
          {activeKey === 2 ?
              <LAFProvider onSaved={() => {
              }} stepCode={OnboardingStepsNamesEnum.BRAND_LAF}>
                <>
                  <LookAndFeel isExitButtonEnabled={false}
                               isHeadlineAllowed={false}/>
                  <LAFContext.Consumer>
                    {({fonts}) => {
                      return <FontsStyle fonts={fonts}/>
                    }}
                  </LAFContext.Consumer>
                </>
              </LAFProvider>
              : null}
        </Accordion.Panel>
        <Accordion.Panel header="Information about your business"
                         eventKey={3}
        >
          {activeKey === 3 ?
              <BusinessInfoProvider generatingPrompt={false} onSaved={() => {
              }} stepCode={OnboardingStepsNamesEnum.LAST_STEP}>
                <BusinessInfoContext.Consumer>
                  {({handleNext, loading}) => {
                    return <>
                      <BusinessInfo isExitButtonEnabled={false}
                                    isHeadlineAllowed={false}/>
                      <div className="my-8 border-t border-lightGray"></div>
                      <Button
                          title={'Save'}
                          variant={'primary'}
                          onClick={handleNext}
                          disabled={false}
                          loading={loading}
                      >Save</Button>
                    </>
                  }}
                </BusinessInfoContext.Consumer>
              </BusinessInfoProvider>
              : null}
        </Accordion.Panel>
      </Accordion>
    </div>
  </div>
}