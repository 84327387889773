import RsAccordion from 'rsuite/Accordion'
import {HTMLAttributes} from 'react'
import './accordion.css'

export type AccordionHeaderParams = {
  title: string
} & HTMLAttributes<HTMLDivElement>

export const AccordionHeader = ({title, ...rest}: AccordionHeaderParams) => {
  return <h1 {...rest} >{title}</h1>
}

export const Accordion = RsAccordion
