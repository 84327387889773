import React from 'react'

import {Button} from '../Button'
import {ReactComponent as EditIcon} from '../../assets/EditIcon.svg'
import {ReactComponent as RefreshIcon} from '../../assets/Refresh.svg'

const labelStyles = {
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '34px',
  color: '#202020',
  fontFamily: 'Inter',
}

export const BrandStyleTab = ({
                                onEdit,
                                onRefresh,
                                label,
                                disabled,
                              }: {
  onRefresh?: () => void
  onEdit?: () => void
  disabled?: boolean
  label: string;
}) => {
  return (
      <div className="flex justify-between items-center">
        <div style={labelStyles}>{label}:
          {onRefresh && (
              <span className={'pl-3 relative top-1'}>
              <button className={'justify-center'} onClick={onRefresh} disabled={disabled}>
                <span><RefreshIcon/></span>
              </button>
            </span>
          )}
        </div>
        <div>
          {onEdit && (
              <Button variant={'outline'} style={{height: '40px'}}
                      onClick={onEdit} disabled={disabled}>
                <span className={'flex'}><span className={'inline-flex'}>Edit<i
                    className={'inline-flex pl-2'}><EditIcon/></i></span></span>
              </Button>
          )}
        </div>
      </div>
  )
}
