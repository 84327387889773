import success from "../../../assets/LOOP.gif";
import { Button } from "../../../components/Button";
import VideoView from "./VideoView";
import { ReactComponent as NextIcon } from "../../../assets/next.svg";
import { Link } from "react-router-dom";
import VideoLoopView from "./VideoLoopView";

export function Step10({
  name,
  showLoading,
  onClick,
}: {
  name?: string;
  showLoading?: boolean;
  onClick?: () => void;
}) {
  return (
    <>
      <div className="w-full md:max-w-[600px] overflow-auto max-h-[calc(100vh-125px)] py-2">
        {/*<div className="flex-1 md:p-5 flex justify-center items-center">*/}
        {/*  <div>*/}
        {/*    <VideoLoopView loopSrc="/media/payment-popup.mp4" />*/}
        {/*  </div>*/}
        {/*</div>*/}
        {showLoading ? (
          <>
            <h1 className="text-dark1 text-center text-lg  md:text-2xl font-semibold leading-normal md:leading-relaxed mb-3">
              {name}, magic is happening!
            </h1>
            <div>
              <p className="text-center mb-6">
                Maximize your Guidely experience! While we're putting together
                your brand kit and first nine posts, check out our demo video
                for tips and tricks.
              </p>
              <p className="text-center mb-6">
                Expect a notification on this screen in 3-5 minutes once your
                first posts are ready!
              </p>

              <p className="text-center">
                Prefer reading? Dive into our{" "}
                <a
                  className="text-primary underline"
                  href="http://coaches.guidely.com/knowledge-base"
                  target="_blank"
                  rel="noreferrer"
                >
                  Knowledge Base.
                </a>
              </p>
            </div>
          </>
        ) : (
          <div className="flex flex-col justify-center">
            <h1 className="text-dark1 text-center text-lg  md:text-2xl font-semibold leading-normal md:leading-relaxed mb-2">
              Congrats! Your first posts are ready!
            </h1>
            <p className="text-center mb-10">
              Keep watching the video, or go directly to the dashboard.
            </p>
            {onClick ? (
              <Button
                onClick={onClick}
                variant="primary"
                className="w-fit self-center"
              >
                Continue
                <NextIcon className="[&_path]:stroke-white" />
              </Button>
            ) : (
              <Link to="/dashboard" className="w-fit self-center">
                <Button
                  onClick={() => {}}
                  variant="primary"
                  className="w-fit self-center"
                >
                  Continue
                  <NextIcon className="[&_path]:stroke-white" />
                </Button>
              </Link>
            )}
          </div>
        )}
      </div>
    </>
  );
  // return (
  //   <>
  //     <div className="w-full md:max-w-[600px] overflow-auto max-h-[calc(100vh-125px)] py-2">
  //       <div className="flex-1 md:p-5 flex justify-center items-center">
  //         <div className="w-[266px] h-[266px]">
  //           <img
  //             src={success}
  //             alt="success"
  //             className="w-full h-full object-cover"
  //           />
  //         </div>
  //       </div>
  //       <h1 className="text-dark1 text-center text-lg  md:text-2xl font-semibold leading-normal md:leading-relaxed">
  //         We're conjuring the magic... <br /> Please don't close or refresh this
  //         page while we create your unique brand and feed!
  //       </h1>
  //       {/* <p className="text-dark2 text-center text-sm leading-normal mb-3 mt-1.5">

  //         <span className="onboarding-loading animate-blink">.</span>
  //         <span className="onboarding-loading animate-blink">.</span>
  //         <span className="onboarding-loading animate-blink">.</span>
  //       </p> */}
  //     </div>
  //     <div className="h-12 overflow-hidden mt-3">
  //       <ul className="block text-left animate-text-slide-5  leading-tight [&_li]:block [&_li]:h-12 [&_li]:p-2 [&_li]:text-center">
  //         <li className="text-dark1 text-center text-sm">
  //           Your unique fonts and colors were hand-selected just for you!
  //         </li>
  //         <li className="text-dark1 text-center text-sm">
  //           Be ready to connect your Instagram account for seamless
  //           auto-posting!
  //         </li>
  //         <li className="text-dark1 text-center text-sm">
  //           A cohesive feed is one that converts! Guidely has a 5 pillar
  //           proprietary posting strategy with proven results.
  //         </li>
  //         <li className="text-dark1 text-center text-sm">
  //           Check out the Knowledge Base for a wealth of information on growing
  //           your business.
  //         </li>
  //         <li className="text-dark1 text-center text-sm">
  //           You can add testimonials to your custom feed - simply use the
  //           content creation wizard! Look for the magic wand icon!
  //         </li>
  //       </ul>
  //     </div>
  //   </>
  // );
}
