import { userInfo } from "os";
import { ReactComponent as Copy } from "../../../assets/ic_content.svg";
import userImage from "../../../assets/user.png";

import { Button } from "../../../components/Button";
import { useState } from "react";

export function SocialMediaProfile({ profile, instagramConnected }) {
  const [isCopied, setIsCopied] = useState(false);
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  };
  return (
    <div className="max-w-[650px] mx-auto lg:mr-0 lg:ml-[90px] flex flex-col md:flex-row md:items-center flex-wrap gap-5 md:gap-20">
      {/** mobile view */}
      <div className="md:hidden flex flex-col gap-2.5">
        <div className="text-gray-900 text-2xl font-light ">
          {typeof profile.INSTAGRAM_HANDLE === "string"
            ? profile.INSTAGRAM_HANDLE
            : "@" + profile?.INSTAGRAM_HANDLE?.username}
        </div>
        <div className="flex gap-12 items-start justify-between">
          <div className="w-[64px] h-[64px]">
            <img
              src={
                profile?.instagramProfilePic ||
                profile?.profilePic ||
                profile?.IMAGES?.[0] ||
                userImage
              }
              alt="profile-pic"
              className="w-full h-full object-cover rounded-full"
            />
          </div>
          <div className="flex items-center gap-6">
            <div className="flex flex-col items-center gap-[2px]">
              <div className="text-center text-black text-sm font-medium">
                {profile?.mediaCount}
              </div>
              <div className="text-black text-xs font-semibold">Posts</div>
            </div>
            <div className="flex flex-col items-center gap-[2px]">
              <div className="text-center text-black text-sm font-medium">
                {profile?.followersCount}
              </div>
              <div className="text-black text-xs font-semibold">Followers</div>
            </div>
            <div className="flex flex-col items-center gap-[2px]">
              <div className="text-center text-black text-sm font-medium">
                {profile?.followsCount}
              </div>
              <div className="text-black text-xs font-semibold">Following</div>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden md:block w-[123px] h-[123px]">
        <img
          src={
            profile?.instagramProfilePic || profile?.profilePic || profile?.IMAGES?.[0] || userImage
          }
          alt="profile-pic"
          className="w-full h-full object-cover rounded-full"
        />
      </div>
      <div className="flex-1 flex-col justify-start items-start gap-6 flex">
        <div className="hidden md:flex justify-center items-center gap-5">
          <div className="text-gray-900 text-2xl font-light ">
            {typeof profile.INSTAGRAM_HANDLE === "string"
              ? profile.INSTAGRAM_HANDLE
              : "@" + profile?.INSTAGRAM_HANDLE?.username}
          </div>
        </div>
        <div className="justify-start items-start gap-4 md:gap-16 hidden md:flex">
          <div className="flex items-center gap-2">
            <div className="text-center text-black text-sm font-medium">
              {profile?.mediaCount}
            </div>
            <div className="text-black text-xs font-medium">Posts</div>
          </div>
          <div className="flex items-center gap-2">
            <div className="text-center text-black text-sm font-medium">
              {profile?.followersCount}
            </div>
            <div className="text-black text-xs font-medium">Followers</div>
          </div>
          <div className="flex items-center gap-2">
            <div className="text-center text-black text-sm font-medium">
              {profile?.followsCount}
            </div>
            <div className="text-black text-xs font-medium">Following</div>
          </div>
        </div>
        <div className="flex-col justify-start items-start gap-1 flex">
          <div className="text-black text-sm font-medium">{profile?.name}</div>
          <div className="text-neutral-400 text-sm font-normal ">
            Product/service
          </div>
          <div className="text-black text-xs font-light flex gap-5">
            <span>{profile.biography}</span>
            <Button
              variant="link"
              onClick={() => handleCopy(profile.biography)}
            >
              <Copy
                className={`w-6 h-6 ${isCopied ? "[&_path]:fill-primary" : ""}`}
              />
            </Button>
          </div>
          {profile.website && (
            <div className="text-sky-900 text-xs font-normal ">
              <a href={profile.website}> {profile.website}</a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
