/**
 * Generated by orval v6.15.0 🍺
 * Do not edit manually.
 * Guidely
 * The backend APIs
 * OpenAPI spec version: 1.0
 */
import useSwr from "swr";
import type { SWRConfiguration, Key } from "swr";
import type { UserGuidesDto } from ".././schemas";
import { customInstance } from "../../customInstance";

export const userGuidesGetUserGuidesChecklist = () => {
  return customInstance<UserGuidesDto>({ url: `/user-guides`, method: "get" });
};

export const getUserGuidesGetUserGuidesChecklistKey = () =>
  [`/user-guides`] as const;

export type UserGuidesGetUserGuidesChecklistQueryResult = NonNullable<
  Awaited<ReturnType<typeof userGuidesGetUserGuidesChecklist>>
>;
export type UserGuidesGetUserGuidesChecklistQueryError = unknown;

export const useUserGuidesGetUserGuidesChecklist = <
  TError = unknown,
>(options?: {
  swr?: SWRConfiguration<
    Awaited<ReturnType<typeof userGuidesGetUserGuidesChecklist>>,
    TError
  > & { swrKey?: Key; enabled?: boolean };
}) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getUserGuidesGetUserGuidesChecklistKey() : null));
  const swrFn = () => userGuidesGetUserGuidesChecklist();

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};
