import React, {useEffect, useState} from 'react'

import {
  CreateTestimonialDto,
  UpdateMultipleTestimonialDto,
} from '../../apis/quidely/schemas'
import {Button} from '../../components/Button'
import {
  testimonialUpdateMultiple,
  useTestimonialFindAll,
} from '../../apis/quidely/testimonials/testimonials'
import {InputLabel} from '../../components/InputLabel'
import {ReactComponent as RemoveIcon} from '../../assets/ic_delete-orange.svg'
import {TextArea} from '../../components/TextArea'
import {Loading} from '../../components/Loading/Loading'
import {ShowToast} from '../../services/toast'
import {Link} from 'react-router-dom'

export type TestimonialFormParams = {
  onSaved: () => void
}

export interface TestimonialFormItemType extends CreateTestimonialDto {
  id?: string
}

export const TestimonialsPostsGenerationCompletedScreen = () => {
  return <>
    <div className="mt-2 text-base font-semibold">Posts for Testimonials are
      generated
      and available for review on <Link className="text-[#0091AE]"
                                        to={'/dashboard'}>Dashboard</Link>
    </div>
  </>
}

export const MAX_TESTIMONIALS_ITEMS_ALLOWED = 4

export const TestimonialsPostsGenerationInProgressScreen = () => {
  return <>
    <div className="mt-2 text-base font-semibold">Posts generation for
      Testimonials in
      progress...
    </div>
  </>
}

export const TestimonialForm = ({onSaved}: TestimonialFormParams) => {
  const [saveStatus, setSaveStatus] = useState(0)
  const defaultState: TestimonialFormItemType[] = [
    {
      testimonial: '',
      description: '',
    }]
  const [formState, setFormState] = useState<TestimonialFormItemType[]>(
      [...defaultState])
  const {isLoading, data: userTestimonialData} = useTestimonialFindAll(
      {isDeleted: false})
  const [submitData, updateToSubmitData] = useState<UpdateMultipleTestimonialDto>(
      {toRemove: [], toUpdate: [], toCreate: []})

  const handleSubmit = async () => {
    if (formState.length && formState.length <=
        MAX_TESTIMONIALS_ITEMS_ALLOWED) {
      const toSubmit: UpdateMultipleTestimonialDto = {...submitData}
      for (const item of formState) {
        if (item.id) {
          toSubmit.toUpdate?.push({
            id: item.id,
            testimonial: item.testimonial,
            description: item.description,
          })
        } else {
          toSubmit.toCreate?.push({
            testimonial: item.testimonial,
            description: item.description,
          })
        }
      }
      try {
        setSaveStatus(1)
        await testimonialUpdateMultiple(toSubmit)
        setSaveStatus(2)
        onSaved()
      } catch (error) {
        console.error('TestimonialForm:handleSubmit:error', error)
        setSaveStatus(3)
        ShowToast({message: 'Failed to save answers', type: 'error'})
      }
    }
  }

  const onAddNewRow = () => {
    setFormState((prev) => {
      return [...prev, ...defaultState]
    })
  }

  const onRemoveRow = (index: number) => () => {
    setFormState((prev) => {
      return prev.filter((item, i) => {
        if (i !== index) {
          return true
        } else if (item?.id) {
          updateToSubmitData((prevState) => {
            let toRemove: string[] = []
            if (prevState.toRemove && Array.isArray(prevState.toRemove)) {
              toRemove = [...prevState.toRemove]
            }
            if (!toRemove.includes(item.id as string)) {
              toRemove.push(item.id as string)
            }
            return {...prevState, toRemove}
          })
        }
        return false
      })
    })
  }

  const onChangeFieldValue = (
      fieldName: string, row: TestimonialFormItemType,
      currentIndex: number) => (event) => {
    const currentItem = {
      ...row,
      [fieldName]: event?.currentTarget?.value,
    }
    setFormState((prev) => prev.map((item, ind) => {
      if (ind !== currentIndex) {
        return item
      }
      return currentItem
    }))
  }

  useEffect(() => {
    if (userTestimonialData && userTestimonialData.length) {
      setFormState(userTestimonialData)
    }
  }, [userTestimonialData])

  if (isLoading) {
    return <Loading/>
  }

  return <div className="">
    {saveStatus === 0 ?
        <div className="mt-2 text-base font-semibold">Testimonials ({formState ?
            formState.length :
            0} / {MAX_TESTIMONIALS_ITEMS_ALLOWED})</div> : null}
    <div className="overflow-auto h-[640px]">
      {saveStatus === 1 ? <TestimonialsPostsGenerationInProgressScreen/> : null}
      {saveStatus === 2 ? <TestimonialsPostsGenerationCompletedScreen/> : null}
      {saveStatus === 0 ? <>
        {formState ? formState.map((row, index) => {
              return <div key={index} className="w-full">
                <fieldset
                    className="mt-4 mr-1 px-3 pb-3 border border-gray-200 shadow-md rounded-lg">
                  {index > 0 ?
                      <legend className="text-center" onClick={onRemoveRow(index)}>
                        <Button style={{padding: '3px 5px'}}
                                variant={'outline'}><RemoveIcon
                            className="inline text-orange-600"/>Remove</Button>
                      </legend> :
                      null}
                  <div className="justify-start">
                    <InputLabel isRequired={true} labelText={'Client description'}
                                className={'xl:h-11 lg:h-11 md:h-11'}/>
                    <TextArea name={`description-${index}`}
                              placeholder={'Client Description (e.g. Issues client was facing)'}
                              value={row.description}
                              onChange={onChangeFieldValue('description', row,
                                  index)}
                              maxLength={1000}
                              required={true}
                              isWordCounterEnabled={true}
                    />
                  </div>
                  <div>
                    <InputLabel isRequired={true}
                                labelText={'Benefits gained from working with you'}
                                className={'xl:h-11 lg:h-11 md:h-11'}/>
                    <TextArea name={`testimonial-${index}`}
                              placeholder={'Client Benefits (e.g. What the client gained from working with you)'}
                              value={row.testimonial}
                              onChange={onChangeFieldValue('testimonial', row,
                                  index)}
                              maxLength={1000}
                              isWordCounterEnabled={true}
                              required={true}
                    />
                  </div>
                </fieldset>
              </div>
            })
            : null}
        {formState && formState.length < MAX_TESTIMONIALS_ITEMS_ALLOWED ?
            <div className="my-4 ml-1 justify-end">
              <Button variant={'outline'} onClick={onAddNewRow}>Add Another
                Testimonial</Button>
            </div>
            : null}
      </> : null}
    </div>
    <div className="flex mt-4 sticky justify-end">
      {saveStatus < 2 ?
          <Button variant={'primary'} type={'submit'} onClick={handleSubmit}
                  loading={saveStatus === 1}>Save
            now</Button> : null}
    </div>
  </div>
}