import {OnboardingStepsEnum, OnboardingStepsNamesEnum} from '../../../types'
import {ActionButtons} from './ActionButtons'
import {clearTokenAndUser} from '../../../services/utils'
import {useNavigate} from 'react-router-dom'
import {LAFContext, LAFProvider, LookAndFeel} from '../../../components/LAF'

export function BrandLookAndFeel({
                                   onBack,
                                   onNext,
                                   metaData,
                                   data,
                                   stepCode,
                                 }: {
  onBack: () => void;
  onNext: (data: any) => void;
  metaData: any;
  data;
  stepCode: OnboardingStepsEnum
}) {
  const navigate = useNavigate()
  const handleBack = (e: React.MouseEvent<HTMLButtonElement>) => {
    onBack()
  }
  const handleClose = async () => {
    clearTokenAndUser()
    navigate('/login')
  }

  return <div className="w-full overflow-auto max-h-[calc(100vh-125px)] py-2">
    <LAFProvider onSaved={onNext} stepCode={OnboardingStepsNamesEnum.BRAND_LAF}>
      <LAFContext.Consumer>
        {({handleNext, loading}) => (<>
              <LookAndFeel handleExitButton={handleClose}
                           isExitButtonEnabled={true}/>
              <div className="my-8 border-t border-lightGray"></div>
              <ActionButtons
                  onBack={handleBack}
                  onNext={handleNext}
                  disableNext={false}
                  loading={loading}
              />
            </>
        )}
      </LAFContext.Consumer>
    </LAFProvider>
  </div>

}