import {ReactNode} from 'react'

import {Modal} from '../../components/Modal'

export type UserGuidesModalParams = {
  title?: string
  description?: string
  formElement: ReactNode
  onClose: () => void
  open: boolean
}

export const UserGuidesModal = ({
                                  onClose,
                                  open,
                                  formElement,
                                  title,
                                  description,
                                }: UserGuidesModalParams) => {

  return <Modal onClose={onClose} open={open} contentClassName="max-w-[80%] !mx-2">
    <>
      {title ? <div className={'py-2'}>
        <h2 className="text-dark1 text-2xl font-semibold">{title}</h2>
      </div> : null}
      {description ? <div className={'text-base py-2'}>
        <div>{description}</div>
      </div> : null}
      {formElement}
    </>
  </Modal>
}
