import {useEffect, useState} from 'react'
import {LoadingScreen} from '../profile/LoadingScreen'
//
// import {ReactComponent as Library} from '../../assets/ic_my_library_books.svg'
// import {ReactComponent as People} from '../../assets/ic_people.svg'
// import {ReactComponent as Poll} from '../../assets/ic_poll.svg'
// import {ReactComponent as PersonAdd} from '../../assets/ic_person_add.svg'
// import {ReactComponent as ThumbDown} from '../../assets/ic_thumb_down.svg'
// import {ReactComponent as ThumbUp} from '../../assets/ic_thumb_up.svg'
// import {ReactComponent as StarIcon} from '../../assets/star.svg'
import {ReactComponent as Favourite} from '../../assets/ic_favorite.svg'
import {ReactComponent as Bookmark} from '../../assets/ic_bookmark.svg'
import {ReactComponent as Send} from '../../assets/ic_send.svg'
import {ReactComponent as CommentIcon} from '../../assets/ic_comment.svg'
import {SocialScore} from './components/SocialScore'
import {SocialMediaProfile} from './components/SocialMediaProfile'
import {PostView} from './components/PostView'
import {Link, useLocation, useOutletContext} from 'react-router-dom'
import {Button} from '../../components/Button'
import {useUser} from '../profile/context/user-context'
import {api} from '../../services/api'
import {getConsent, getUser} from '../../services/utils'
import {BrandingColorImageModal} from './components/BrandingColorImageModal'
// import {ConsentConfirmationModal} from './components/ConsentConfirmationModal'
import {GetStarted} from './components/GetStarted'
import {pageView} from '../../services/mixpanel'
import {ConnectInstagram} from '../onboarding/components/ConnectInstagram'
import {ShowToast} from '../../services/toast'
import {useApp} from '../../services/app-context'
import React from 'react'

const storyLabels = [
  'Start Here',
  'FAQs',
  'Freebies',
  'Testimonials',
  'Work with Me',
]

export function Dashboard() {
  const {instagramConnected, fetchInstagramConnected} = useOutletContext<{
    instagramConnected: boolean;
    fetchInstagramConnected: (userId: string) => void;
  }>()
  const {dashboard, isSubscriptionActive, loading, setLoading, fetchDashboardData} =
      useApp() as any
  const {_id} = getUser()
  const user = useUser() as any
  const [showConsentModal, setShowConsentModal] = useState(false)
  const [confirmingConsent, setConfirmingConsent] = useState(false)
  const [brandingStyle, setBrandingStyle] = useState<any>({})
  const [instagramConnectModal, setInstagramConnectModal] = useState(false)
  const [brandingImageModal, setBrandingImageModal] = useState<string | null>(
      null,
  )
  const locationInstance = useLocation()
  // const fetchDashboardData = async (
  //   userId: string,
  //   shouldRefreshWholePage?: boolean
  // ) => {
  //   shouldRefreshWholePage !== false && setLoading(true);
  //   try {
  //     const res = await api.getDashboard(userId);
  //     setDashboard(res);
  //   } catch (error) {
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const fetchBrandStyleInfo = (id) => {
    setLoading(true)
    api
        .getBrandStyles(id)
        .then((response) => {
          setBrandingStyle(response)
          setLoading(false)
        })
        .catch((error) => {
          setLoading(false)
        })
  }

  const handleRefresh = (shouldRefreshWholePage?: boolean) => {
    fetchDashboardData(_id, shouldRefreshWholePage)
  }

  const closeConsentModal = () => {
    setShowConsentModal(false)
  }

  const handleInstagramModalClose = () => {
    setInstagramConnectModal(false)
    handleRefresh()
    user.fetchUser(_id)
  }

  const handleInstagramConnectRefresh = () => {
    handleRefresh()
    fetchInstagramConnected(_id)
  }

  const handleConfirmConsent = async () => {
    try {
      setConfirmingConsent(true)
      const response = await api.saveUserProfile(_id, {
        canAutoSchedulePost: true,
      })
      setConfirmingConsent(false)
      if ([200, 201].includes(response.status)) {
        await user.fetchUser(_id)
      } else {
        ShowToast({
          type: 'error',
          message: 'Error confirming consent',
        })
      }
    } catch (error) {
      setConfirmingConsent(false)
      ShowToast({
        type: 'error',
        message: 'Error confirming consent',
      })
    }
  }

  useEffect(()=>{
    if (locationInstance?.search === '?subscribed=true') {
      console.log({searchParams: locationInstance.search})
      window?.location?.replace('/dashboard')
    }
  }, [locationInstance.search])

  useEffect(() => {
    if (!_id) return;
    fetchDashboardData(_id);
    fetchBrandStyleInfo(_id);
    const consent = getConsent();
    const contains = consent?.includes(_id);
    if (contains) return;
    setShowConsentModal(true);
  }, [_id]);

  useEffect(() => {
    pageView()
  }, [])

  if (loading) {
    return (
        <div className="relative bg-ultraLightGray p-4 md:p-6">
          <LoadingScreen/>
        </div>
    )
  }
  return (
      <div className="relative bg-ultraLightGray p-4 md:p-6">
        <div className="max-w-[1440px] mx-auto p-4 md:p-6">
          <h1 className="font-semibold text-4xl mb-8">
            Hi, {user?.userInfo?.FIRST_NAME || user?.userInfo?.DISPLAY_NAME}
          </h1>
          <GetStarted
              open={showConsentModal}
              onClose={closeConsentModal}
              confirmingConsent={confirmingConsent}
              onInstagramConnect={() => {
                setInstagramConnectModal(true)
              }}
              instagramConnected={instagramConnected}
              isSubscriptionActive={isSubscriptionActive}
          />
          <div className="flex gap-4 flex-wrap">
            <div className="bg-white flex-1 p-6 rounded-lg shadow">
              <SocialMediaProfile
                  profile={{...(user?.userInfo || {}), ...dashboard.profile}}
                  instagramConnected={instagramConnected}
              />
              <div
                  className="md:gap-10 flex flex-wrap items-center mt-12 lg:ml-16">
                {brandingStyle?.colors?.map((item, i) => (
                    <div
                        key={i}
                        className="flex-1 md:flex-none flex flex-col justify-center gap-3 items-center"
                    >
                      <Button
                          variant="link"
                          className="w-10 h-10 md:w-[70px] md:h-[70px] border border-[#DFDFDF] !p-1 !rounded-full"
                          onClick={() => setBrandingImageModal(item)}
                      >
                        <div
                            className="w-full h-full rounded-full"
                            style={{backgroundColor: item}}
                        >
                          {/* <StarIcon className="w-[30px] h-[30px] md:w-[60px] md:h-[60px]" /> */}
                        </div>
                      </Button>
                      <span className="text-black text-[8px] md:text-xs">
                    {storyLabels[i]}
                  </span>
                    </div>
                ))}
              </div>
              <PostView
                  refresh={handleRefresh}
                  posts={dashboard?.posts || []}
                  initialPostsGenerationStatus={
                    dashboard?.initialPostsGenerationStatus
                  }
                  isSubscriptionActive={isSubscriptionActive}
              />
            </div>
            <div className="flex flex-col gap-5">
              <SocialScore score={dashboard?.profile?.socialScore || 0}/>
              {instagramConnected && (
                  <div
                      className="bg-white shadow w-full md:w-[259px] p-6 rounded-lg">
                    <h3 className="text-lg font-semibold text-dark1">
                      Top 3 Performing posts
                    </h3>
                    <div className="flex flex-col gap-4 mt-4">
                      {dashboard?.topPosts?.map((item) => (
                          <div
                              key={item._id}
                              className="flex justify-between gap-4 border-b border-lightGray pb-4"
                          >
                            <div className="flex flex-col gap-2 flex-1 w-full">
                              <div className="flex gap-2">
                                <div className="w-16 h-16 rounded">
                                  {item.mediaType === 'VIDEO' ? (
                                      <video
                                          className="w-full h-full object-cover rounded"
                                          src={item.mediaUrl}
                                      />
                                  ) : (
                                      <img
                                          src={item.mediaUrl}
                                          alt="post"
                                          className="w-full h-full object-cover rounded"
                                      />
                                  )}
                                </div>
                                <span
                                    className="flex-1 font-medium text-sm text-dark1 line-clamp-2 max-h-[40px] text-ellipsis">
                            {item.caption}
                          </span>
                              </div>
                              <div className="flex gap-[0.625rem] items-center">
                                <div className="flex flex-1 items-center gap-1">
                                  <Favourite/>
                                  <span className="text-dark3 text-sm">
                              {item.likesCount}
                            </span>
                                </div>
                                <div className="flex flex-1 items-center gap-1">
                                  <CommentIcon/>
                                  <span className="text-dark3 text-sm">
                              {item.commentsCount}
                            </span>
                                </div>
                                <div className="flex flex-1 items-center gap-1">
                                  <Send/>
                                  <span className="text-dark3 text-sm">
                              {item.sharesCount}
                            </span>
                                </div>
                                <div className="flex flex-1 items-center gap-1">
                                  <Bookmark/>
                                  <span className="text-dark3 text-sm">
                              {item.savesCount}
                            </span>
                                </div>
                              </div>
                            </div>
                          </div>
                      ))}

                      <div className="flex justify-center">
                        <Link to="/progress" className="w-full md:w-auto">
                          <Button
                              variant="outline"
                              className="!py-2 w-full md:w-auto"
                          >
                            Check your Progress
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </div>
              )}
            </div>
          </div>
        </div>
        {!!brandingImageModal && (
            <BrandingColorImageModal
                open={!!brandingImageModal}
                onClose={() => setBrandingImageModal(null)}
                color={brandingImageModal}
            />
        )}
        {instagramConnectModal && (
            <ConnectInstagram
                open={instagramConnectModal}
                onClose={handleInstagramModalClose}
                handleRefresh={handleInstagramConnectRefresh}
                data={user?.userInfo || {}}
                handleConsent={handleConfirmConsent}
            />
        )}
      </div>
  )
}
