import React, {useEffect, useState} from 'react'

import {
  CreateCtaDto,
  UpdateMultipleCtaDto,
} from '../../apis/quidely/schemas'
import {Button} from '../../components/Button'
import {TextInput} from '../../components/TextInput'
import {
  ctaUpdateMultiple,
  useCtaFindAllUserCta,
} from '../../apis/quidely/cta/cta'
import {InputLabel} from '../../components/InputLabel'
import {ReactComponent as RemoveIcon} from '../../assets/ic_delete-orange.svg'
import {TextArea} from '../../components/TextArea'
import {Loading} from '../../components/Loading/Loading'
import {ShowToast} from '../../services/toast'
import {SelectInput} from '../../components/SelectInput'
import {selectBoxItems} from '../../types'
import {Link} from 'react-router-dom'

export type CtaFormParams = {
  onSaved: () => void
}

export interface CtaFormItemType extends CreateCtaDto {
  id?: string
}

export const CtaPostsGenerationCompletedScreen = () => {
  return <>
    <div className="mt-2 text-base font-semibold">Posts for CTA are generated
      and available for review on <Link className="text-[#0091AE]"
                                        to={'/dashboard'}>Dashboard</Link>
    </div>
  </>
}

export const CtaPostsGenerationInProgressScreen = () => {
  return <>
    <div className="mt-2 text-base font-semibold">Posts generation for CTA in
      progress...
    </div>
  </>
}

export const MAX_CTA_ITEMS_ALLOWED = 6

export const CtaForm = ({onSaved}: CtaFormParams) => {
  const [saveStatus, setSaveStatus] = useState(0)
  const defaultState = [{action: '', service: '', description: ''}]
  const [formState, setFormState] = useState<CtaFormItemType[]>(
      [...defaultState])
  const {isLoading, data: userCtaData} = useCtaFindAllUserCta(
      {isDeleted: false})
  const [submitData, updateToSubmitData] = useState<UpdateMultipleCtaDto>(
      {toRemove: [], toUpdate: [], toCreate: []})

  const handleSubmit = async () => {
    if (formState.length && formState.length <= MAX_CTA_ITEMS_ALLOWED) {
      const toSubmit: UpdateMultipleCtaDto = {...submitData}
      for (const item of formState) {
        if (item.id) {
          toSubmit.toUpdate?.push({
            id: item.id,
            service: item.service,
            description: item.description,
            action: item.action,
          })
        } else {
          toSubmit.toCreate?.push({
            service: item.service,
            description: item.description,
            action: item.action,
          })
        }
      }
      try {
        setSaveStatus(1)
        await ctaUpdateMultiple(toSubmit)
        setSaveStatus(2)
        onSaved()
      } catch (error) {
        console.error('CtaForm:handleSubmit:error', error)
        setSaveStatus(3)
        ShowToast({message: 'Failed to save answers', type: 'error'})
      }
    }
  }

  const onAddNewRow = () => {
    setFormState((prev) => {
      return [...prev, ...defaultState]
    })
  }

  const onRemoveRow = (index: number) => () => {
    setFormState((prev) => {
      return prev.filter((item, i) => {
        if (i !== index) {
          return true
        } else if (item?.id) {
          updateToSubmitData((prevState) => {
            let toRemove: string[] = []
            if (prevState.toRemove && Array.isArray(prevState.toRemove)) {
              toRemove = [...prevState.toRemove]
            }
            if (!toRemove.includes(item.id as string)) {
              toRemove.push(item.id as string)
            }
            return {...prevState, toRemove}
          })
        }
        return false
      })
    })
  }

  const onChangeFieldValue = (
      fieldName: string, row: CtaFormItemType,
      currentIndex: number) => (event) => {
    const currentItem = {
      ...row,
      [fieldName]: event?.currentTarget?.value,
    }
    setFormState((prev) => prev.map((item, ind) => {
      if (ind !== currentIndex) {
        return item
      }
      return currentItem
    }))
  }

  useEffect(() => {
    if (userCtaData && userCtaData.length) {
      setFormState(userCtaData)
    }
  }, [userCtaData])

  if (isLoading) {
    return <Loading/>
  }

  return <div>
    {saveStatus === 0 ?
        <div className="mt-2 text-base font-semibold">Offering ({formState ?
            formState.length :
            0} / {MAX_CTA_ITEMS_ALLOWED})</div> :
        null}
    <div className="overflow-auto h-[640px]">
      {saveStatus === 1 ? <CtaPostsGenerationInProgressScreen/> : null}
      {saveStatus === 2 ? <CtaPostsGenerationCompletedScreen/> : null}
      {saveStatus === 0 ? <>
        {formState ? formState.map((row, index) => {
              return <div key={index} className="w-full">
                <fieldset
                    className="mt-4 mr-1 px-3 pb-3 border border-gray-200 shadow-md rounded-lg">
                  {index > 0 ?
                      <legend className="text-center" onClick={onRemoveRow(index)}>
                        <Button style={{padding: '3px 5px'}}
                                variant={'outline'}><RemoveIcon
                            className="inline text-orange-600"/>Remove</Button>
                      </legend> :
                      null}
                  <div>
                    <InputLabel isRequired={true} labelText={'Service'}
                                className={'xl:h-11 lg:h-11 md:h-11'}/>
                    <TextInput name={`service-${index}`}
                               value={row.service}
                               onChange={onChangeFieldValue('service', row, index)}
                               type={'text'}/>
                  </div>
                  <div className="justify-start">
                    <InputLabel isRequired={false} labelText={'Description'}
                                className={'xl:h-11 lg:h-11 md:h-11'}/>
                    <TextArea name={`description-${index}`}
                              value={row.description}
                              onChange={onChangeFieldValue('description', row,
                                  index)}
                              maxLength={1000}
                              isWordCounterEnabled={true}
                    />
                  </div>
                  <div>
                    <InputLabel isRequired={true} labelText={'Action'}
                                className={'xl:h-11 lg:h-11 md:h-11'}/>
                    <SelectInput name={`action-${index}`}
                                 data={selectBoxItems}
                                 value={row.action}
                                 type={'text'}
                                 onChange={onChangeFieldValue('action', row, index)}
                    />
                  </div>
                </fieldset>
              </div>
            })
            : null}
        {formState && formState.length < MAX_CTA_ITEMS_ALLOWED ?
            <div className="my-4 ml-1 justify-end">
              <Button variant={'outline'} onClick={onAddNewRow}>Add Another
                Offering</Button>
            </div>
            : null}
      </> : null}
    </div>
    <div className="flex mt-4 sticky justify-end">
      {saveStatus < 2 ?
          <Button variant={'primary'} type={'submit'} onClick={handleSubmit}
                  loading={saveStatus === 1}>Save
            now</Button> :
          null}
    </div>
  </div>
}