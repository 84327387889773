import React from 'react'
import {useNavigate} from 'react-router-dom'

import {OnboardingStepsEnum, OnboardingStepsNamesEnum} from '../../../types'
import {clearTokenAndUser} from '../../../services/utils'
import {ActionButtons} from './ActionButtons'
import {
  BusinessInfoContext,
  BusinessInfoProvider,
  BusinessInfo,
} from '../../../components/BusinessInfo'
import {Step10} from './Step10'

export function LastStep({
                           onBack,
                           generatingPrompt,
                           setGeneratingPrompt,
                           handleFinalStepOnSuccess,
                         }: {
  onBack: () => void;
  onNext: (data: any) => void;
  metaData: any;
  data;
  stepCode: OnboardingStepsEnum,
  setGeneratingPrompt: (value: boolean) => void;
  generatingPrompt: boolean;
  handleFinalStepOnSuccess: () => void
}) {
  const navigate = useNavigate()

  const handleBack = (e: React.MouseEvent<HTMLButtonElement>) => {
    onBack()
  }

  const handleClose = async () => {
    clearTokenAndUser()
    navigate('/login')
  }

  return <div className="w-full overflow-auto max-h-[calc(100vh-125px)] py-2">
    <BusinessInfoProvider stepCode={OnboardingStepsNamesEnum.LAST_STEP}
                          onSaved={() => {
                            handleFinalStepOnSuccess()
                          }}
                          generatingPrompt={generatingPrompt}
                          setGeneratingPrompt={setGeneratingPrompt}
    >
      <BusinessInfoContext.Consumer>
        {({loading, handleNext, promptLoading, images, onSaved}) => {
          return <>
            {generatingPrompt ?
                <div className={'flex justify-center items-center'}>
                  <Step10
                      name={'Post generation'}
                      showLoading={promptLoading}
                      onClick={() => {
                        // not to update the state
                        onSaved({
                          BRAND_VOICE: [],
                          IMAGES: images,
                        })
                        navigate('/dashboard')
                      }}
                  />
                </div>
                : <>
                  <BusinessInfo isExitButtonEnabled={true}
                                handleExitButton={handleClose}/>
                  <div className="my-8 border-t border-lightGray"></div>
                  <ActionButtons
                      onBack={handleBack}
                      onNext={handleNext}
                      disableNext={false}
                      loading={loading}
                  />
                </>}
          </>
        }}
      </BusinessInfoContext.Consumer>
    </BusinessInfoProvider>

  </div>
}