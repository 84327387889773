import React from 'react'

import {Modal} from '../Modal'
import {Button} from '../Button'
import {Loading} from '../Loading/Loading'
import {ColorChangeConfirm} from './ColorChangeConfirm'
import {ColorInput} from './ColorInput'
import {BrandStyleTab} from '../BrandStyles'

export const colorTitles = [
  'Primary Brand Color',
  'Secondary Dark',
  'Secondary Light',
  'Accent',
  'Neutral',
]

export const BrandColors = ({
                              colors,
                              onUpdate,
                              onRefresh,
                            }: {
  colors: string[];
  onUpdate: (data: string[]) => any;
  onRefresh?: () => void
}) => {
  const [editConfirm, setEditConfirm] = React.useState<boolean>(false)
  const [edit, setEdit] = React.useState<boolean>(false)
  const [editColors, setEditColors] = React.useState<string[]>([])
  const [saving, setSaving] = React.useState<boolean>(false)

  const handleEdit = () => {
    setEdit(true)
    setEditConfirm(false)
    setEditColors(colors)
  }

  const handleClose = () => {
    setEdit(false)
  }

  const handleUpdate = async () => {
    setSaving(true)
    const response = await onUpdate(editColors)
    setSaving(false)
    if (response) {
      setEdit(false)
    }
  }

  const disabled = !colors || (colors && Array.isArray(colors) && !colors.length)

  return (
      <div className={'border border-gray-200 rounded-lg p-4'}>
        <BrandStyleTab label="Colors" onEdit={handleEdit}
                       onRefresh={onRefresh} disabled={disabled}/>
        <div className="flex gap-5 flex-wrap mt-4">
          {colors && colors.length ? colors.map((color, i) => {
            return (<div className="w-52 h-20 relative" key={color}>
              <div
                  className="w-52 h-20 left-0 top-0 absolute rounded-lg"
                  style={{backgroundColor: color}}
              />
              <div
                  className="left-[14px] top-[39px] absolute text-white text-lg font-semibold font-['Inter'] leading-normal">
                {colorTitles[i]}
              </div>
            </div>)
          }) : <Loading/>}
        </div>
        <Modal
            contentClassName="max-w-[525px] !mx-2"
            open={edit}
            onClose={handleClose}
        >
          <div>
            <div className="text-neutral-800 text-2xl font-semibold">
              Edit Colors
            </div>
            <div className="flex gap-5 flex-wrap mt-4 flex-col md:flex-row">
              {editColors.map((color, index) => (
                  <ColorInput
                      value={color}
                      onChange={(value) => {
                        const newColors = [...editColors]
                        newColors[index] = value
                        setEditColors(newColors)
                      }}
                      key={index}
                      title={colorTitles[index]}
                  />
              ))}
            </div>
            <div className="mt-8 flex justify-end">
              <Button variant="primary" onClick={handleUpdate} loading={saving}>
                Update
              </Button>
            </div>
          </div>
        </Modal>
        {editConfirm && (
            <ColorChangeConfirm
                onClose={() => setEditConfirm(false)}
                onConfirm={handleEdit}
                open={editConfirm}
            />
        )}
      </div>
  )
}
