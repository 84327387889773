import {InputHTMLAttributes} from 'react'
import {ReactComponent as CheckboxIcon} from '../../assets/ic_checkbox.svg'

export type CheckboxParams = {
  isChecked?: boolean
  isReadOnly?: boolean
  name?: string
  isHoverEffectEnabled?: boolean
}

export const Checkbox = ({
                           name,
                           isChecked,
                           isReadOnly,
                           isHoverEffectEnabled,
                           ...props
                         }: InputHTMLAttributes<HTMLInputElement> & CheckboxParams) => {
  const hoverClasses = isHoverEffectEnabled ? ' hover:before:opacity-10': ''
  return <div className={'relative flex items-center rounded-full'}>
    <input type="checkbox"
           readOnly={isReadOnly}
           checked={isChecked}
           name={name}
           className={`before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-orange-600 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-orange-500 checked:bg-white-500 checked:before:bg-orange-500${hoverClasses}`}
           {...props}
    />
    {isChecked ? <span
        className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
        <CheckboxIcon/>
      </span> : null}
  </div>
}