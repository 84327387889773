import { Modal } from "../../../components/Modal";
import { useUser } from "../../profile/context/user-context";
import userImage from "../../../assets/user.png";
import { Button } from "../../../components/Button";
import { SelectInput } from "../../../components/SelectInput";
import { DateTimePicker } from "./DateTimePicker";
import { ReactComponent as ImageIcon } from "../../../assets/ic_image.svg";
import { ReactComponent as MagicWand } from "../../../assets/magic-wand.svg";
import { ReactComponent as EmojiIcon } from "../../../assets/ic_emoji.svg";
import { useEffect, useMemo, useRef, useState } from "react";
import { TagPeople } from "./TagPeople";
import { LocationInput } from "./LocationInput";
import { fileUpload } from "../../../services/file-upload";
import { getUser } from "../../../services/utils";
import { AiPostGenerator } from "../AiPostGenerator";
import { trackEvent } from "../../../services/mixpanel";
import { api } from "../../../services/api";
import { PostDeleteConfirm } from "./PostDeleteConfirm";
import { ShowToast } from "../../../services/toast";
import Tippy from "@tippyjs/react";
import { FileUploader } from "react-drag-drop-files";
import { Loading } from "../../../components/Loading/Loading";
import data from "@emoji-mart/data";
import EmojiPicker from "@emoji-mart/react";
import { PaymentView } from "./PaymentView";
// import VideoView from "../../onboarding/components/VideoView";

export function CreatePostForm({
  open,
  onClose,
  postForm,
  setPostForm,
  onSaveDraft,
  onSchedule,
  onRefresh,
  hideAITool = false,
  saving,
  savingDraft,
  currentScheduleDateTime,
  onDelete,
  isSubscriptionActive,
}: {
  open: boolean;
  onClose: () => void;
  postForm: any;
  setPostForm: any;
  onSchedule: any;
  onSaveDraft: (post: any) => void;
  onRefresh: (post: any) => void;
  hideAITool?: boolean;
  saving?: boolean;
  savingDraft?: boolean;
  currentScheduleDateTime?: any;
  onDelete?: () => void | Promise<void>;
  isSubscriptionActive?: boolean;
}) {
  const isSaving = saving || savingDraft;
  const user = getUser();
  const imageRef = useRef<HTMLInputElement>(null);
  const [showAiGenerator, setShowAiGenerator] = useState(false);
  const [profilePicLoading, setProfilePicLoading] = useState<boolean>(false);
  const [emojiModal, setEmojiModal] = useState<boolean>(false);
  const [images, setImages] = useState<any>(null); // [file, setFile]
  const [usernames, setUsernames] = useState<any>(null); // [file, setFile
  const [generating, setGenerating] = useState<boolean>(false);

  const [deletingPost, setDeletingPost] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const { userInfo } = useUser() as any;
  const isPaused = !userInfo?.canAutoSchedulePost;

  const handleSchedule = () => {
    onSchedule(postForm);
  };

  const handleSaveDraft = () => {
    onSaveDraft(postForm);
  };

  const handleFileUpload = async (files: any) => {
    const file = files[0];
    setProfilePicLoading(true);
    const uploadResponse = await fileUpload(file, user._id, "USER_THUMBNAIL");
    if (uploadResponse.url) {
      setPostForm((form) => ({ ...form, imageUrl: uploadResponse.url }));
    }
    setProfilePicLoading(false);
  };

  const handleAutoPostGenerate = async () => {
    setShowAiGenerator(true);
    const trackData: any = {
      userId: user?._id,
    };
    if (postForm?._id) {
      trackData.id = postForm?._id;
    }
    trackEvent("Create with Content Wizard", {
      ...trackData,
    });
  };

  const handleCloseAiGenerator = () => {
    setShowAiGenerator(false);
  };

  const handleDeletePost = () => {
    if (!postForm._id) {
      onClose();
      return;
    } else {
      setShowDeleteConfirm(true);
    }
  };

  const handleCloseDeleteConfirm = () => {
    setShowDeleteConfirm(false);
  };

  const handleDeleteConfirm = async () => {
    try {
      if (!postForm._id) {
        onClose();
        setShowDeleteConfirm(false);
        return;
      }
      setDeletingPost(true);
      const response = await api.deletePost(postForm._id);
      setDeletingPost(false);
      if (response.status === 200) {
        ShowToast({
          message: "Post deleted successfully",
          type: "success",
        });
        setShowDeleteConfirm(false);
        onDelete && (await onDelete());
        onClose();
      } else {
        ShowToast({
          message: "Something went wrong",
          type: "error",
        });
      }
    } catch (error) {
      console.error(error);
      setDeletingPost(false);
      ShowToast({
        message: "Something went wrong",
        type: "error",
      });
    }
  };

  const isScheduleDateTimeChanged = useMemo(() => {
    if (postForm._id) {
      if (currentScheduleDateTime) {
        const current = new Date(currentScheduleDateTime);
        const newSchedule = new Date(postForm.scheduleDateTime);
        return current.getTime() !== newSchedule.getTime();
      } else if (postForm.scheduleDateTime) {
        return true;
      }
      return false;
    }
    return true;
  }, [currentScheduleDateTime, postForm.scheduleDateTime, postForm._id]);

  const openEmojiView = (e) => {
    e.stopPropagation();
    setEmojiModal(true);
  };

  const closeEmojiView = () => {
    setEmojiModal(false);
  };

  const isPostReadOnly = useMemo(() => {
    if (!postForm._id || !postForm.ayrsharePostId) return false;
    if (postForm.scheduleDateTime) {
      const scheduleDateTime = new Date(postForm.scheduleDateTime);
      const now = new Date();
      return scheduleDateTime.getTime() < now.getTime();
    }
    return false;
  }, [postForm._id, postForm.scheduleDateTime, postForm.ayrsharePostId]);

  const handlePostRefresh = async () => {
    if (!postForm._id) return;
    try {
      setGenerating(true);
      const response = await api.generateImage(postForm);
      setGenerating(false);
      var timestamp = new Date().getTime();
      if (response && response.data && response.data.imageUrl) {
        const imageUrl = response.data.imageUrl + "?t=" + timestamp;
        setPostForm({ ...postForm, ...response.data, imageUrl });
      }
    } catch (error) {
      console.error(error);
      setGenerating(false);
    }
  };

  const disabled = useMemo(() => {
    if (!postForm.caption) return true;
    if (!postForm.imageUrl) return true;
    if (!postForm.scheduleDateTime) return true;
    // if postForm has data and scheduleDateTime is less than 5 minutes from now
    // then return true
    if (
      postForm._id &&
      postForm.ayrsharePostId &&
      new Date(postForm.scheduleDateTime).getTime() <
        new Date().getTime() + 5 * 60 * 1000
    )
      return true;
    return false;
  }, [
    postForm.caption,
    postForm.imageUrl,
    postForm.scheduleDateTime,
    postForm._id,
    postForm.ayrsharePostId,
  ]);

  useEffect(() => {
    // event listener for closing emoji picker
    // outside click
    const handleClickOutside = (event) => {
      const container = document.querySelector(".emoji-picker");
      if (
        container &&
        container !== event.target &&
        !container.contains(event.target)
      ) {
        closeEmojiView();
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [emojiModal]);
  if (!open) return null;
  return (
    <Modal
      contentClassName="pb-20 md:pb-0 !px-0 lg:max-w-[940px] !mx-0"
      open={open}
      onClose={onClose}
      closeButtonStyle="right-8 top-8 lg:-right-8 lg:top-0 [&>svg_path]:stroke-black lg:[&>svg_path]:stroke-white"
    >
      <div className="overflow-y-auto max-h-[calc(100vh-50px)]">
        {!isSubscriptionActive && <PaymentView isFromPostModal={true} />}
        <div className={`px-5 md:pb-5 pt-5 relative`}>
          {!isSubscriptionActive && (
            <div className="bg-black/20 absolute z-[2] top-0 bottom-0 left-0 right-0" />
          )}
          <h1 className="text-neutral-800 text-lg font-semibold mb-6 leading-normal">
            {!isSubscriptionActive ? "Example post" : "Schedule post"}
          </h1>
          <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center gap-5">
            <div className="w-fit px-4 py-3 bg-white rounded-lg border border-primary justify-start items-center gap-2 inline-flex">
              <div className="w-9 h-9 rounded-full">
                <img
                  src={
                    userInfo?.instagramProfilePic ||
                    userInfo?.IMAGES?.[0] ||
                    userImage
                  }
                  alt="profile-pic"
                  className="w-full h-full rounded-full object-fit"
                />
              </div>
              <div className="flex-col gap-0.5 flex">
                <span className="text-dark1 text-sm font-medium">
                  @
                  {typeof userInfo.INSTAGRAM_HANDLE === "string"
                    ? userInfo.INSTAGRAM_HANDLE
                    : userInfo?.INSTAGRAM_HANDLE?.username}
                </span>
                <span className="text-dark3 text-xs font-normal">
                  Instagram
                </span>
              </div>
            </div>
            <div className="flex flex-wrap md:flex-nowrap gap-5 items-center">
              <SelectInput
                data={[
                  { label: "Call-to-Action (CTA)", value: "CALL_TO_ACTION" },
                  { label: "Informational", value: "INFORMATIONAL" },
                  { label: "Testimonials", value: "TESTIMONIALS" },
                  { label: "Personal Post", value: "PERSONAL_POST" },
                  { label: "Share your Story", value: "SHARE_YOUR_STORY" },
                ]}
                showBlankOption={false}
                value={postForm.type}
                onChange={(e: any) => {
                  setPostForm((form) => ({ ...form, type: e.target.value }));
                  // handleRegenerate({...postForm, type: e.target.value})
                }}
                className="!py-2 !bg-white !rounded"
                disabled={isPostReadOnly}
              />
              {(!isPaused || isPostReadOnly) && (
                <DateTimePicker
                  onChange={(date: any) =>
                    setPostForm((form) => ({ ...form, scheduleDateTime: date }))
                  }
                  value={postForm.scheduleDateTime}
                  readOnly={isPostReadOnly}
                  isPaused={isPaused}
                  isDraft={postForm._id && !postForm.ayrsharePostId}
                />
              )}
              {!hideAITool && isSubscriptionActive && (
                <Button
                  variant="outline"
                  onClick={handleAutoPostGenerate}
                  className="!px-4"
                  disabled={saving || savingDraft || isPostReadOnly}
                >
                  <Tippy
                    className="mb-2"
                    arrow={true}
                    content={
                      <div className="text-white whitespace-nowrap text-[13px] p-2 bg-black/70 rounded-[10px] shadow ">
                        Generate a post using AI
                      </div>
                    }
                  >
                    <MagicWand className="!outline-none" />
                  </Tippy>
                </Button>
              )}
            </div>
          </div>
          <div className="flex flex-wrap justify-center md:justify-start gap-8 border border-solid border-lightGray rounded-lg bg-white p-4 mt-6">
            <FileUploader
              handleChange={(file) =>
                !profilePicLoading &&
                !isPostReadOnly &&
                handleFileUpload([file])
              }
              name="file"
              types={["JPG", "JPEG"]}
              disabled={profilePicLoading || isPostReadOnly}
            >
              <div className="relative cursor-pointer w-[300px] group h-[300px] bg-[#F6F6F6] rounded flex flex-col items-center justify-center gap-4">
                {profilePicLoading && (
                  <div className="absolute w-full h-full flex justify-center items-center bg-black/20">
                    <Loading />
                  </div>
                )}
                {postForm.imageUrl ? (
                  <>
                    <img
                      src={postForm.imageUrl}
                      alt=""
                      className="w-full h-full object-cover rounded"
                      onClick={() => imageRef.current?.click()}
                    />
                    {!(profilePicLoading || isPostReadOnly) && (
                      <div className="absolute hidden px-3 py-2 rounded group-hover:flex items-center gap-2 bg-dark1">
                        <ImageIcon className="w-6 h-6 [&>path]:fill-white" />
                        <span className="text-sm font-medium text-white/50">
                          Edit
                        </span>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <ImageIcon />
                    <span className="text-sm text-dark3">
                      Choose a file or drag it here
                    </span>
                    <Button
                      variant="outline"
                      className="py-1.5 bg-white !border-lightGray"
                      // onClick={() => {
                      //   imageRef.current?.click();
                      // }}
                    >
                      Upload
                    </Button>
                    <input
                      type="file"
                      className="hidden"
                      ref={imageRef}
                      accept="image/jpg, image/jpeg"
                      onChange={(e) => {
                        const files = e.target.files;
                        if (files?.length) {
                          handleFileUpload(files);
                        }
                        e.target.value = "";
                        e.target.files = null;
                      }}
                    />
                  </>
                )}
              </div>
            </FileUploader>
            <div className="flex-1">
              <div>
                <button
                  className="absolute right-10"
                  onClick={emojiModal ? closeEmojiView : openEmojiView}
                  disabled={isPostReadOnly}
                >
                  <EmojiIcon />
                </button>
                {emojiModal && (
                  <div className="emoji-picker absolute top-20 right-20">
                    <EmojiPicker
                      data={data}
                      onEmojiSelect={(emoji) => {
                        setPostForm((form) => ({
                          ...form,
                          caption: (form.caption || "") + emoji.native,
                        }));
                      }}
                    />
                  </div>
                )}
                <textarea
                  className="w-full h-32 rounded-lg border-none text-sm !outline-none p-4 pr-8"
                  placeholder="Write a caption…"
                  value={postForm.caption}
                  onChange={(e) =>
                    setPostForm((form) => ({
                      ...form,
                      caption: e.target.value,
                    }))
                  }
                  readOnly={isPostReadOnly}
                />
              </div>
              <div className="rounded-lg border border-lightGray w-full my-4">
                <TagPeople
                  value={postForm.tags || ""}
                  onChange={(value) => {
                    setPostForm((form) => ({ ...form, tags: value }));
                  }}
                  readOnly={isPostReadOnly}
                />
                <div className="border-b border-lightGray" />
                <LocationInput
                  value={postForm.location}
                  onChange={(e) =>
                    setPostForm((form) => ({
                      ...form,
                      location: e.target.value,
                    }))
                  }
                  readOnly={isPostReadOnly}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-wrap flex-col-reverse md:flex-row justify-between items-center mt-6">
            {isSubscriptionActive ? (
              <div className="hidden md:flex gap-2">
                <Button
                  variant="outline"
                  onClick={handleDeletePost}
                  disabled={isPostReadOnly}
                >
                  Discard post
                </Button>
                {postForm._id && (
                  <div className="hidden md:block">
                    <Button
                      loading={generating}
                      variant="outline"
                      onClick={handlePostRefresh}
                      disabled={isPostReadOnly}
                    >
                      Refresh
                    </Button>
                  </div>
                )}
              </div>
            ) : null}
            <div className="flex flex-wrap md:flex-nowrap gap-5 items-center">
              {isSubscriptionActive ? (
                <div className="flex items-center justify-between gap-4 md:justify-end w-full md:w-auto">
                  <Button
                    variant="outline"
                    className="flex-1 whitespace-nowrap"
                    loading={savingDraft}
                    disabled={
                      isSaving ||
                      disabled ||
                      generating ||
                      postForm.ayrsharePostId
                    }
                    onClick={handleSaveDraft}
                  >
                    Save Draft
                  </Button>
                  {!isPaused && (
                    <Button
                      variant="primary"
                      className="flex-1"
                      loading={saving}
                      disabled={isSaving || disabled || generating}
                      onClick={handleSchedule}
                    >
                      {isScheduleDateTimeChanged ? "Schedule" : "Save"}
                    </Button>
                  )}
                </div>
              ) : null}
              {postForm._id && (
                <div className="flex flex-1 md:hidden gap-5">
                  <Button
                    loading={generating}
                    variant="outline"
                    onClick={handlePostRefresh}
                    className="flex-1"
                    disabled={isPostReadOnly || isSaving}
                  >
                    Refresh
                  </Button>
                  <Button
                    variant="outline"
                    className="md:hidden flex-1"
                    onClick={handleDeletePost}
                    disabled={isPostReadOnly || isSaving}
                  >
                    Discard post
                  </Button>
                </div>
              )}
            </div>
          </div>
          {showAiGenerator && (
            <AiPostGenerator
              onClose={handleCloseAiGenerator}
              open={showAiGenerator}
              onRefresh={onRefresh}
              postData={postForm}
            />
          )}

          <PostDeleteConfirm
            onClose={handleCloseDeleteConfirm}
            onConfirm={handleDeleteConfirm}
            open={showDeleteConfirm}
            deletingPost={deletingPost}
          />
        </div>
        {/*{!isSubscriptionActive && (*/}
        {/*  <>*/}
        {/*    <div className="w-full flex justify-center">*/}
        {/*      <div className="bg-white md:w-[400px] p-8 md:h-[320px] [&_svg]:!h-[138px] [&_svg]:!w-[138px] [&>div]:!w-full [&>div]:!h-full">*/}
        {/*        <VideoView src="/media/payment-popup.mp4" />*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*    <h1 className="text-dark2 text-center text-sm font-medium mb-4">*/}
        {/*      Dive into the full feature set - watch the demo video above!*/}
        {/*    </h1>*/}
        {/*  </>*/}
        {/*)}*/}
      </div>
    </Modal>
  );
}
