import React, {TextareaHTMLAttributes} from 'react'
import {ReactComponent as CloseIcon} from '../../assets/x.svg'

export type TextAreaParams = {
  isWordCounterEnabled?: boolean
  hasError?: boolean
  errorElement?: React.ReactNode
  errorMessage?: string
  onClose?: (params?: any, event?: any) => void
} & TextareaHTMLAttributes<HTMLTextAreaElement>

export function TextArea({
                           isWordCounterEnabled,
                           minLength,
                           maxLength,
                           hasError,
                           errorElement,
                           errorMessage,
                           onClose,
                           ...props
                         }: TextAreaParams) {
  const borderClasses = hasError ?
      'border-red focus:border-red2' :
      'border-lightGray focus:border-grey2'
  const inputStyles = hasError ? {borderColor: 'red'} : {}

  const getCurrentCharsLimit = (currentCount: number) => {
    const minLen = (minLength || 0)
    const maxLen = (maxLength || 0)
    if (minLen === 0 && maxLen > 0) {
      return `${currentCount}/max ${maxLen} (${maxLen -
      currentCount} left to maximum)`
    }
    if (minLen > 0 && currentCount < minLen) {
      return <b
          className="text-xs text-[#ff8672]">{currentCount}/min {minLength} ({minLen -
      currentCount} left to minimum)</b>
    } else if (currentCount >= minLen && currentCount <= maxLen) {
      return `${currentCount}/max ${maxLen} (${maxLen -
      currentCount} left to maximum)`
    } else if (maxLen > 0 && currentCount > maxLen) {
      return (
          <b className="text-xs text-red-400">{currentCount}/max {maxLen} ({currentCount -
          maxLen} over of maximum allowed)</b>)
    }
  }
  return (
      <div className="w-full">
        <>
          {hasError ? <div className={'m-1'}>
            <div
                className="box bg-white p-3 relative border border-lightGray shadow-md rounded-lg"
            >

              <button
                  onClick={onClose}
                  className="transition duration-75 hover:scale-110 absolute bg-primary rounded-full -top-2 -right-2 p-1"
              >
                <CloseIcon className="w-4 h-4"/>
              </button>
              {errorElement ? errorElement : errorMessage}
            </div>
          </div> : null}
          <textarea
              {...props}
              style={inputStyles}
              className={`!outline-none w-full bg-ultraLightGray focus:bg-white rounded-lg border ${borderClasses} p-5 text-base text-black ${
                  props.className || ''}`}
          />
          {isWordCounterEnabled ?
              <span className={'text-xs text-gray float-right'}>{props.value ?
                  getCurrentCharsLimit(props.value.toString().length) :
                  getCurrentCharsLimit(0)}</span> :
              null}
        </>
      </div>
  )
}
