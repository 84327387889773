export enum CtaActionsNamesEnum {
  clickOnBioLink = 'Click the link in my bio',
  commentBelow = 'Comment below',
  sendDM = 'Send me a DM',
  bookSession = 'Book a discovery session with me',
}

export enum CtaActionsEnum {
  clickOnBioLink = 'clickOnBioLink',
  commentBelow = 'commentBelow',
  sendDM = 'sendDM',
  bookSession = 'bookSession',
}

export const selectBoxItemsObject = {
  [CtaActionsEnum.clickOnBioLink]: CtaActionsNamesEnum.clickOnBioLink,
  [CtaActionsEnum.commentBelow]: CtaActionsNamesEnum.commentBelow,
  [CtaActionsEnum.sendDM]: CtaActionsNamesEnum.sendDM,
  [CtaActionsEnum.bookSession]: CtaActionsNamesEnum.bookSession,
}

export const selectBoxItems = CtaActionsNamesEnum &&
    Object.entries(CtaActionsNamesEnum).map(([value, label]) => {
      return {
        label,
        value,
      }
    })