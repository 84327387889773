import React from 'react'
import {useMainUserProfileContext} from './MainUserProfile.context'
import {Button} from '../Button'
import {InputLabel} from '../InputLabel'
import {TextInput} from '../TextInput'
import {Keywords} from '../Keywords'

export type MainUserProfileParams = {
  isExitButtonEnabled?: boolean
  isHeadlineAllowed?: boolean
  handleExitButton?: () => void
}

export const MainUserProfile = ({
                                  handleExitButton,
                                  isExitButtonEnabled,
                                  isHeadlineAllowed = true,
                                }: MainUserProfileParams) => {
  const {
    form,
    setForm,
    errors,
    onCloseError,
    loading,
    onSelectKeywords,
    currentStepData,
  } = useMainUserProfileContext()

  return currentStepData ? (
      <>
        {isHeadlineAllowed ? <div className={'flex place-content-between'}>
          <div>
            <h1 className="text-dark1 text-2xl font-semibold leading-loose">
              {currentStepData.title}
            </h1>
          </div>
          {isExitButtonEnabled ? <div className={'items-end'}>
            <Button variant={'primary'}
                    className="btn btn-primary relative float-right right-1 top-1 mt-0 mr-0 mb-5 p-3"
                    onClick={handleExitButton}>
              Exit
            </Button>
          </div> : null}
        </div> : null}
        <div className="flex flex-col gap-4 md:flex-row">
          <div
              className={'flex-1 2xl:w-1/2 xl:w-1/2 lg:w-full md:w-full sm:w-full'}>
            <InputLabel isRequired={true}
                        className={'xl:h-11 lg:h-11 md:h-11'}
                        labelText={'What name would you like to appear in your profile and social media posts?'}/>
            <TextInput
                placeholder="Display name"
                onChange={(e) =>
                    setForm((prev) => ({...prev, displayName: e.target.value}))
                }
                value={form?.displayName}
                disabled={loading}
                maxLength={100}
                size={50}
            />
          </div>
          <div
              className={'flex-1 2xl:w-1/2 xl:w-1/2 lg:w-full md:w-full sm:w-full'}>
            <InputLabel isRequired={true}
                        className={'xl:h-11 lg:h-11 md:h-11'}
                        labelText={'What’s your Instagram handle?'}/>
            <TextInput
                placeholder={currentStepData.questions['instagramHandle'].placeholder}
                onChange={(e) =>
                    setForm(
                        (prev) => ({...prev, instagramHandle: e.target.value}))
                }
                value={form?.instagramHandle}
                disabled={loading}
                maxLength={100}
                size={50}
                onClose={onCloseError}
                hasError={!!errors.instagramHandle}
                errorElement={<div style={{width: '480px'}}><h2
                    className={'text-xl font-bold text-[#54595E]'}>Instagram
                  Account Not
                  Found</h2><p className={'text-sm text-[#54595E]'}>Please
                  check the handle you entered and confirm it’s correct. If you
                  don’t have an Instagram account currently, you can enter what
                  the account name will be and continue.</p></div>}
            />
          </div>
        </div>
        <div className="box mt-5">
          <InputLabel isRequired={false}>Would you like to use something other
            than your IG handle as a watermark image on your posts? If yes,
            please enter the name of your website or business
            below.</InputLabel>
          <TextInput
              placeholder={currentStepData.questions['watermark'].placeholder}
              onChange={(e) =>
                  setForm(
                      (prev) => ({...prev, watermark: e.target.value}))
              }
              value={form?.watermark}
              disabled={loading}
              maxLength={255}
          />
        </div>
        <div className="mt-5">
          <InputLabel isRequired={true}>Which of the values below best
            describes you and your coaching style? Please choose up to 5. You
            can always change these later in your profile.</InputLabel>
          <Keywords
              keywords={currentStepData.questions['keywords'].keywords as Array<string>}
              selectedKeywords={currentStepData.questions['keywords']?.answer as Array<string>}
              onSelected={onSelectKeywords}
          />
        </div>
      </>
  ) : null
}